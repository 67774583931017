import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import useApiServices from "../../utils/useApiServices";
import {
  // GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER,
  GET_MECHANT_PAYOUT_MASTERS_BY_VEHICLE,
  GET_BALANCE_BY_ID,
  GET_ALL_ACTIVE_VEHICAL_MASTER,
} from "../../utils/constant";

const CheckBalance = ({ toggleRightBar }) => {
  const { apiCallFnforGet } = useApiServices();
  const [formData, setFormData] = useState({
    vehicleId: "",
    merchantId: "",
  });
  const [vehicles, setVehicles] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchantBalance, setSelectedMerchantBalance] = useState(null);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const apiResponse = await apiCallFnforGet(
          GET_ALL_ACTIVE_VEHICAL_MASTER
        );
        if (apiResponse.statusCode === 200) {
          const vehicleList = apiResponse.data
            .filter((item) => item.vehicleName.toLowerCase().includes("payout"))
            .map((item) => ({
              id: item.id,
              vehicleName: item.vehicleName,
            }));
          setVehicles(vehicleList);
        } else {
          swal({
            title: "Error",
            text: "Failed to fetch vehicles.",
            icon: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        swal({
          title: "Error",
          text: "Something went wrong while fetching vehicles.",
          icon: "error",
        });
      }
    };

    fetchVehicles();
  }, []);

  const fetchBalanceById = async (id) => {
    try {
      const apiResponse = await apiCallFnforGet(
        `${GET_BALANCE_BY_ID}?id=${id}`
      );

      if (apiResponse.statusCode === 200) {
        // Parse the 'data' field as JSON to extract the balance
        const parsedData = JSON.parse(apiResponse.data);
        return parsedData; // Return the balance directly
      } else {
        const errorMessage = apiResponse.message || "Failed to fetch balance.";
        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
        return null;
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
      swal({
        title: "Error",
        text: "Something went wrong while fetching balance.",
        icon: "error",
      });
      return null;
    }
  };

  const fetchMerchantsByVehicle = async (vehicleId) => {
    try {
      const apiResponse = await apiCallFnforGet(
        `${GET_MECHANT_PAYOUT_MASTERS_BY_VEHICLE}/${vehicleId}`
      );

      if (apiResponse.statusCode === 200) {
        setMerchants(apiResponse.data);
      } else {
        swal({
          title: "Error",
          text: "Failed to fetch merchants.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching merchants:", error);
      swal({
        title: "Error",
        text: "Something went wrong while fetching merchants.",
        icon: "error",
      });
    }
  };

  const handleVehicleSelect = (e) => {
    const vehicleId = e.target.value;
    setFormData({ ...formData, vehicleId, merchantId: "" });
    setSelectedMerchantBalance(null); // Reset balance on vehicle change
    if (vehicleId) {
      fetchMerchantsByVehicle(vehicleId);
    } else {
      setMerchants([]);
    }
  };

  const handleMerchantSelect = async (e) => {
    const merchantId = e.target.value;
    setFormData({ ...formData, merchantId });

    if (merchantId) {
      const balance = await fetchBalanceById(merchantId);
      if (balance !== null) {
        setSelectedMerchantBalance(balance); // Set the numeric balance
      } else {
        setSelectedMerchantBalance(null);
      }
    } else {
      setSelectedMerchantBalance(null);
    }
  };

  return (
    <div className="container-fluid">
      <div className="justify-content-between bg-white">
        {/* Header Section */}
        <div className="d-flex justify-content-start align-items-center">
          <button
            type="button"
            className="btn-close m-3"
            onClick={toggleRightBar}
          ></button>
          <div className="fs-2 text-dark fw-bold">Check Payout Balance</div>
        </div>

        {/* Select Vehicle Section */}
        <div className="col-sm-12 col-md-6 offset-md-3">
          <label className="d-flex text-dark fs-4">
            Vehicle Master
            <sup className="text-danger mt-3">*</sup>
          </label>
          <div className="d-flex gap-2">
            <select
              name="vehicleId"
              value={formData.vehicleId || ""}
              onChange={handleVehicleSelect}
              className="form-control"
            >
              <option value="">Select Vehicle Master</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.vehicleName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Select Merchant Section */}
        <div className="col-sm-12 col-md-6 offset-md-3 mt-3">
          <label className="d-flex text-dark fs-4">
            Merchant
            <sup className="text-danger mt-3">*</sup>
          </label>
          <div className="d-flex gap-2">
            <select
              name="merchantId"
              value={formData.merchantId || ""}
              onChange={handleMerchantSelect}
              className="form-control"
              disabled={!formData.vehicleId}
            >
              <option value="">Select Account</option>
              {merchants.map((merchant) => (
                <option key={merchant.primaryId} value={merchant.primaryId}>
                  {merchant.primaryId} - {merchant.companyName}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Display the balance box */}
        {/* {selectedMerchantBalance !== null && ( */}
        <div className="mt-4 p-3 border rounded shadow-sm bg-light d-flex flex-column align-items-center justify-content-between">
          <h5 className="text-dark">Merchant Details</h5>
          
          <pre
            style={{
              width: "fit-content",
              background: "#f4f4f4",
              padding: "10px",
              borderRadius: "5px",
              color: "black",
              fontSize: "1rem",
              textAlign: "left",
            }}
          >
            {JSON.stringify(
              selectedMerchantBalance,
              null,
              2
            )}
          </pre>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default CheckBalance;
