import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FlagEditForm from "./FlagEditForm";
import { FETCH_FLAG_MANAGEMENT_API } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
// import { BiEdit } from "react-icons/bi";
import useCheckPrivilages from "../../utils/checkPrivilages";

function FlagSubmitted({ toggleRightBar, type1 }) {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMerchantMid, setSelectedMerchantMid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { apiCallFnforGet } = useApiServices();

  const hasPrivilage = useCheckPrivilages(2696);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedMerchantMid("");
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedMerchantMid("");
  };

  useEffect(() => {
    apiCallFnforGet(FETCH_FLAG_MANAGEMENT_API)
      .then((response) => {
        if (response.statusCode === 200) {
          // setMerchants(response.data);
          setMerchants(response.data);
        } else if (response.statusCode === 304) {
          setMerchants(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [isEditing]);

  const openDetails = (mid) => {
    const selectedMerchant = merchants.find((merchant) => merchant.mid === mid);

    if (selectedMerchant && selectedMerchant.isServiceChargeFound === true) {
      setSelectedMerchantMid(mid);
      setIsEditing(true);
      setIsModalOpen(true);
    } else {
      swal({
        icon: "error",
        title: "Service Charge Not Active",
        text: "You are not able to update this merchant as the on-Boarding process is not completed.",
      });
    }
  };

  const columns = [
    { headerName: "Company Name", field: "merchantName", minWidth: 200 },
    { headerName: "Merchant ID", field: "mid", minWidth: 200 },
    { headerName: "Withdrawl(A/M)", field: "isWithdrawlAuto", minWidth: 200 },
    { headerName: "Edit Profile(E/D)", field: "isProfileEdit", minWidth: 200 },
    { headerName: "Payout(A/M)", field: "isPayoutAutoApprovel", minWidth: 200 },
    { headerName: "Vendor(A/M)", field: "isVendorAutoApproved", minWidth: 200 },
    { headerName: "Hold(A/M)", field: "isAmountHold", minWidth: 200 },
    { headerName: "Login(E/D)", field: "isActive", minWidth: 200 },
    {
      headerName: "Payout Transactions",
      field: "isPayoutActive",
      minWidth: 200,
    },
    {
      headerName: "Maximum Payout Limit",
      field: "maximumPayoutLimit",
      minWidth: 200,
    },
    {
      headerName: "Minimum Payout Limit",
      field: "minimumPayoutLimit",
      minWidth: 200,
    },
    {
      headerName: "Account Withdraw Manual",
      field: "isWithdrawalAcountActive",
      minWidth: 200,
    },
    {
      headerName: "Payout Withdraw Manual",
      field: "isWithdrawalPayoutActive",
      minWidth: 200,
    },
    {
      headerName: "Minimum Wallet Balance",
      field: "minimumWalletBalance",
      minWidth: 200,
    },
    {
      headerName: "Transaction (E/D)",
      field: "isTransactionAllow",
      minWidth: 200,
    },
    {
      headerName: "Payout Charge After Limit Exceeds",
      field: "payoutChargeAfterLimitExceed",
      minWidth: 200,
    },
    { headerName: "Action", field: "action", minWidth: 200 },
  ];

  const mappedData = merchants?.map((item) => {
    const mappedItem = { ...item };

    mappedItem.isVendorAutoApproved =
      item.isVendorAutoApproved === true
        ? "Auto"
        : item.isVendorAutoApproved === false
        ? "Manual"
        : "-";

    mappedItem.isWithdrawlAuto =
      item.isWithdrawlAuto === true
        ? "Auto"
        : item.isWithdrawlAuto === false
        ? "Manual"
        : "-";
    mappedItem.isPayoutActive =
      item.isPayoutActive === true
        ? "Enable"
        : item.isPayoutActive === false
        ? "Disable"
        : "-";
    mappedItem.isWithdrawalPayoutActive =
      item.isWithdrawalPayoutActive === true
        ? "Enable"
        : item.isWithdrawalPayoutActive === false
        ? "Disable"
        : "-";
    mappedItem.isWithdrawalAcountActive =
      item.isWithdrawalAcountActive === true
        ? "Enable"
        : item.isWithdrawalAcountActive === false
        ? "Disable"
        : "-";
    mappedItem.minimumWalletBalance = item.minimumWalletBalance;
    mappedItem.isProfileEdit =
      item.isProfileEdit === true
        ? "Enable"
        : item.isProfileEdit === false
        ? "Disable"
        : "-";
    mappedItem.isPayoutAutoApprovel =
      item.isPayoutAutoApprovel === true
        ? "Auto"
        : item.isPayoutAutoApprovel === false
        ? "Manual"
        : "-";
    mappedItem.isAmountHold =
      item.isAmountHold === true
        ? "Auto"
        : item.isAmountHold === false
        ? "Manual"
        : "-";
    mappedItem.isActive =
      item.isActive === true
        ? "Enable"
        : item.isActive === false
        ? "Disable"
        : "-";
    mappedItem.isTransactionAllow =
      item.isTransactionAllow === true
        ? "Enable"
        : item.isTransactionAllow === false
        ? "Disable"
        : "-";
    mappedItem.userCreditType = item.userCreditType;
    mappedItem.minimumPayoutLimit = item.minimumPayoutLimit;
    mappedItem.payoutChargeAfterLimitExceed = item.payoutChargeAfterLimitExceed;
    mappedItem.action = (
      <button
        className="badge badge-rounded badge-warning"
        onClick={() => openDetails(item.mid)}
      >
        Edit
      </button>
    );
    return mappedItem;
  });

  {
    /* <button className="badge badge-rounded badge-warning" onClick={() => openDetails(item.mid)}>
        Edit
      </button> */
  }
  return (
    <>
      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <div className=" d-flex justify-content-between bg-white ">
          <div className="d-flex justify-content-start align-items-center">
            <button
              type="button"
              class="btn-close m-3"
              onClick={toggleRightBar}
            ></button>

            <div className="fs-2 text-dark fw-bold">Flag Management </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                <div className="card-body">
                  <div>
                    <DataGrid
                      getRowId={(row) => row.mid}
                      rows={mappedData}
                      columns={columns.map((column) => ({
                        ...column,
                        renderCell: (params) =>
                          column.field === "active" || column.field === "action"
                            ? params.value
                            : params.value,
                      }))}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </div>
                  <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title className="fs-4 fw-bold">
                        Flag Modification
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FlagEditForm
                        mid={selectedMerchantMid}
                        showModal={isModalOpen}
                        closeModal={handleCloseModal}
                        data={merchants}
                        onCloseModal={onCloseModal}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default FlagSubmitted;
