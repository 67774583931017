import React, { useState } from "react";
import Dropdown from "./Dropdown";

const FloatingIcon = ({ setCondition, propToReceive }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "9999",
        width: isOpen ? "700px" : "100px",
        transition: "width 0.3s ease",
        overflow: "visible",
      }}
    >
      <div
        className="bg-secondary rounded rounde-5"
        style={{
          height: "50px",
          width: "auto",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          // padding: "0px 10px"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Dropdown
          className="w-100"
          isOpen={isOpen}
          setCondition={setCondition}
          propToReceive={propToReceive}
        />
      </div>
    </div>
  );
};

export default FloatingIcon;
