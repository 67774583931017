import React, { useState, useEffect, useCallback } from "react";
import { FETCH_ALL_MERCHANT_VALIDATIONS } from "../utils/constant";
import useApiServices from "../utils/useApiServices";
import { debounce } from "lodash";
export default function MerchantDropdown({ isOpen, merchantData, setMid, reset }) {
  const { apiCallFnforGet } = useApiServices();
  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [mid, setMidState] = useState(""); // Renamed `setMid` to avoid conflict with prop name

  // useEffect(() => {
    const getMerchantData = async () => {
      try {
        const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

        if (response?.statusCode === 200) {
          setData(response?.data);
        } else {
          setData([]);
        }
      } catch (error) {
        setData([]);
      }
    };

  //   getMerchantData();
  // }, []);

  const debouncedMiscellenious =useCallback((
    debounce(()=>{

      getMerchantData();
    },0)
  ),[]
  )

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);



  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = data?.find(
      (merchant) => 
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMidState(selectedMerchant.mid); // Update local state
      setMid(selectedMerchant.mid); 
      
      
      // Update prop state
    }
    if(!selectedMerchant){
      setMidState("");
      setMid("");
    }

  
  };

  useEffect(()=>{
    if(reset === true || reset === false){
      setSelectedCompany("Select Merchant")
    }
      },[reset])

  return (
    <div className="d-flex justify-content-evenly gap-2 py-1 px-3">
      {!isOpen && (
        <div
          className={`badge fs-10 badge-primary w-100 ml-1`}
          style={{ cursor: "pointer" }}
        >
          {selectedCompany ? (
            <>{selectedCompany}</>
          ) : (
            <>Select Merchant</>
          )}
        </div>
      )}
      {isOpen && (
        <div className="">
          <select
            id="companyDropdown"
            className="badge fs-10 badge-primary w-100 ml-1"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Combined Merchants</option>
            {data?.map((merchant) => (
              <option style={{textAlign:"left"}} key={merchant.mid} 
              value={`${merchant.mid} :- ${merchant.merchantName}`}>
                {merchant.mid} :- {merchant.merchantName} 
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
