import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import {
  MDR_TYPE_FETCH,
  WITHDRAW_TYPE,
  GET_ALL_FOR_ACCOUNT,
  ACCOUNT_DETAILS_UPLOAD,
  GET_DEFAULT_SERVICES_MDR,
  DEFAULT_SERVICES_MDR,
} from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import swal from "sweetalert";

import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../utils/helper";

const MDRForm = ({ show, onHide, refresh, setRefresh }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [paymentService, setPaymentServices] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    fullName: "",
  });
  const [MDRTypes, setMDRType] = useState([]);
  const [isPayoutServiceTaken, setIsPayoutServiceTaken] = useState(false);

  const [MDRData, setMDRData] = useState([]);
  const [defaultPayoutServicesMDR, setDefaultPayoutServicesMDR] = useState({
    perTxnUpi: 0,
    perTxnImps: 0,
    perTxnRtgs: 0,
    perTxnNeft: 0,
  });
  const [withdrawType, setWithdrawType] = useState([]);
  const [option, setOptions] = useState(0);
  const [accountData, setAccountData] = useState({
    bussinessYear: 0,
    Chargeback: 0,
    fillingYears: 0,
    ticketSize: 0,
    monthlyVolume: 0,
    monthlyTicket: 0,
    withdraw: Number,
    withdrawType: false,
    holdPercentage: 0,
    depositeLumpsum: 0,
    depositePercentage: 0,
    guarantyAmount: 0,
    withdrawType: 0,
    withdrawalFlag: false,
  });

  const [token, setToken] = useState(localStorage.getItem("token"));
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();

  const DefaultServicesList = [
    {
      name: "perTxnUpi",
      paymentTypeId: 1,
      paymentService: "UPI",
    },
    {
      name: "perTxnImps",
      paymentTypeId: 2,
      paymentService: "IMPS",
    },
    {
      name: "perTxnNeft",
      paymentTypeId: 3,
      paymentService: "NEFT",
    },
    {
      name: "perTxnRtgs",
      paymentTypeId: 4,
      paymentService: "RTGS",
    },
  ];

  const checkIsPayoutExistInService = (MDRTypes) => {
    if (!MDRTypes) return false;
    const ServiceIds = MDRTypes.map((service) => {
      return service.paymentTypeId;
    });
    const ServiceNames = MDRTypes.map((service) => {
      return service.paymentservice;
    });
    console.log(ServiceIds, ServiceNames);
    // alert(ServiceNames.includes("PAYOUT") && ServiceIds.includes(6))
    return ServiceNames.includes("PAYOUT") && ServiceIds.includes(6);
  };

  const fetchtheTypeOfoption = async () => {
    setMDRData([]);
    setMDRType([]);
    try {
      const MDRType = await apiCallFnforGet(MDR_TYPE_FETCH + formData?.mid);
      setMDRType(MDRType?.data);

      let keys = MDRType?.data?.map((elem) => elem.paymentTypeId);
      setIsPayoutServiceTaken(checkIsPayoutExistInService(MDRType?.data));

      if (keys != null) {
        for (let d of keys) {
          setMDRData((prevData) => {
            return { ...prevData, [d]: 0 };
          });
        }
      }

      const withdrawType = await apiCallFnforGet(WITHDRAW_TYPE);
      setWithdrawType(withdrawType?.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchtheTypeOfoption(formData.mid);
    fetchDefaultServicesMDR();
  }, [formData.mid]);

  useEffect(() => {
    apiCallFnforGet(GET_ALL_FOR_ACCOUNT)
      .then((response) => {
        if (response.statusCode === 200) {
          setMerchantData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [callAgain]);

  const handleCompanyChange = (event) => {
    console.log(event.target.value);
    const selectedCompany = event.target.value;
    if (selectedCompany === "Blank") {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
      setSelectedCompany("");
      return;
    }
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData?.find(
      (merchant) => merchant.mid === selectedCompany
    );

    if (selectedMerchant) {
      setPaymentServices(selectedMerchant.paymentService);
      setFormData({
        mid: selectedMerchant.mid || "",
        email: selectedMerchant.email || "",
        paymentService: selectedCompany.paymentService,
        fullName: selectedMerchant.authorizedPersonName || "",
      });
    } else {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
    }
  };

  const handleAccoundPhase3data = (e, name) => {
    const value = e.target.value;
    if (
      stopCharacterInput(e.target.value, true) ||
      stopSpecialCharacterInput(e.target.value)
    ) {
      return;
    }
    if (value < 0 || value > 100) {
      return;
    }

    setAccountData({
      ...accountData,
      [e.target.name]: parseFloat(e.target.value),
    });
  };

  const handleMDRChange = (fieldName, value, mdrId) => {
    const parsedValue = parseFloat(value);

    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    if (parsedValue < 0 || parsedValue > 100) {
      return;
    }

    setMDRData({
      ...MDRData,
      [mdrId]: parsedValue,
    });
  };

  const handleDefaultServicesMDRChange = (name, fieldName, value, mdrId) => {
    console.log(name, fieldName, value, mdrId);
    const parsedValue = parseFloat(value);
    console.log(name);

    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    // if (parsedValue < 0 || parsedValue > 100) {
    //   return;
    // }

    setDefaultPayoutServicesMDR({
      ...defaultPayoutServicesMDR,
      [name]: parsedValue,
    });
  };

  useEffect(() => {
    console.log(defaultPayoutServicesMDR);
  }, [defaultPayoutServicesMDR]);

  const fetchDefaultServicesMDR = async () => {
    setMDRData([]);
    setMDRType([]);
    // if (!mid === "") {
    try {
      const MDRType = await apiCallFnforPost(
        GET_DEFAULT_SERVICES_MDR + formData?.mid,
        ""
      );
      if (MDRType?.statusCode === 200) {
        // setIsPayoutServiceTaken(checkIsPayoutExistInService(MDRType?.data));
        const { perTxnImps, perTxnRtgs, perTxnNeft, perTxnUpi, mid } =
          MDRType?.data;
        setDefaultPayoutServicesMDR({
          perTxnImps,
          perTxnRtgs,
          perTxnNeft,
          perTxnUpi,
          mid,
        });
      } else if (MDRType?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "No details found",
          icon: "error",
        });
        setIsPayoutServiceTaken(false);
        setDefaultPayoutServicesMDR({
          mid: "",
          perTxnUpi: 0,
          perTxnImps: 0,
          perTxnRtgs: 0,
          perTxnNeft: 0,
        });
        // setMid("");
        setSelectedCompany("");
        // setFullName("");
      }
    } catch (error) {
      console.log(error);
    }

    // }
  };
  const handleDefaultServicesMDRsubmit = async () => {
    if (formData?.mid === "") {
      swal("Alert", "Please select a merchant.", "warning");
      return;
    }

    try {
      const response = await apiCallFnforPost(DEFAULT_SERVICES_MDR, {
        ...defaultPayoutServicesMDR,
        mid: formData.mid,
      });

      // return response?.data;

      if (response.statusCode === 200) {
        setDefaultPayoutServicesMDR({
          mid: "",
          perTxnUpi: 0,
          perTxnImps: 0,
          perTxnRtgs: 0,
          perTxnNeft: 0,
        });
        // setMid("");
        setSelectedCompany("");

        swal({
          title: "Success",
          text: "Merchant fixed charges updated successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    let data = Object.entries(MDRData);

    for (let d of data) {
      if (isNaN(d[1])) {
        swal({
          title: "ALERT",
          text: "Please Enter MDR",
          icon: "error",
        });
        return;
      }
    }

    let mdrdata = data?.map((elem) => {
      return {
        paymentTypeId: elem[0],
        mdrPercentage: elem[1],
        mid: formData.mid,
      };
    });

    const payload = {
      chargebackDeclarationPercentage: accountData.Chargeback || 0,
      depositeLumpsum: accountData.depositeLumpsum || 0,
      depositePercentage: accountData.depositePercentage || 0,
      holdPercentage: accountData.holdPercentage || 0,
      mid: formData.mid || 0,
      mdrDataRequests: mdrdata,
    };

    try {
      const response = await apiCallFnforPost(ACCOUNT_DETAILS_UPLOAD, payload);
      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Application Saved Successfully",
          icon: "success",
        });
        onHide();
        setAccountData({
          Chargeback: 0,
          holdPercentage: 0,
          depositeLumpsum: 0,
          depositePercentage: 0,
          guarantyAmount: 0,
        });
        setMDRData({});
        setFormData({
          email: "",
          mid: "",
          paymentService: "",
          fullName: "",
        });
        setSelectedCompany("");
        setCallAgain(!callAgain);
        onHide();
        setRefresh(!refresh);
      } else if (response.statusCode === 306) {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleClose = () => {
    onHide();
    setSelectedCompany("");
    setFormData({
      email: "",
      mid: "",
      paymentService: "",
      fullName: "",
    });
    setRefresh(!refresh);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-4  fw-bold">MDR Form</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column align-items-center w-100">
            <div className="w-100">
              <div
                className="mx-auto p-3 rounded"
                style={{ maxWidth: "900px" }}
              >
                <div>
                  <div className="d-flex justify-content-center mb-4">
                    <div className="w-50">
                      <div className="mb-3">
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          Select A Merchant
                        </label>
                        <select
                          id="companyDropdown"
                          className="form-select"
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                          name="merchantSelect"
                        >
                          <option value="Select">Select Merchant</option>
                          {merchantData?.map((merchant) => (
                            <option key={merchant.mid} value={merchant.mid}>
                              {merchant.companyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 mb-4">
                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(33.3333% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          Merchant ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="merchantId"
                          placeholder="Merchant ID"
                          value={formData.mid}
                          readOnly
                        />
                      </div>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(33.3333% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          placeholder="Full Name"
                          value={formData.fullName}
                          readOnly
                        />
                      </div>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(33.3333% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          e-Mail ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="email ID"
                          value={formData.email}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h1></h1>

                  <div className="d-flex flex-wrap gap-2 mb-4">
                    <div className="w-100 mb-3">
                      <label className="d-block mb-1 fs-5 fw-bold text-dark">
                        Deposit<sup className="text-danger">*</sup>
                      </label>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(50% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          In Lumsum <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="depositeLumpsum"
                          value={accountData.depositeLumpsum}
                          onChange={handleAccoundPhase3data}
                        />
                      </div>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(50% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          In Percentage <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="depositePercentage"
                          value={accountData.depositePercentage}
                          onChange={handleAccoundPhase3data}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 mb-4">
                    <div className="w-100 mb-3">
                      <label className="d-block mb-1 fs-5 fw-bold text-dark">
                        Hold<sup className="text-danger">*</sup>
                      </label>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(50% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          In Percentage <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="holdPercentage"
                          value={accountData.holdPercentage}
                          onChange={handleAccoundPhase3data}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 mb-4">
                    <div className="w-100 mb-3">
                      <label className="d-block mb-1 fs-5 fw-bold text-dark">
                        Chargeback declaration
                        <sup className="text-danger">*</sup>
                      </label>
                    </div>

                    <div
                      className="flex-grow-1 mb-3"
                      style={{ minWidth: "calc(50% - 10px)" }}
                    >
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          In Percentage <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="Chargeback"
                          value={accountData.Chargeback}
                          onChange={handleAccoundPhase3data}
                        />
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="d-flex flex-wrap gap-2 mb-4">
                    <div className="w-100 mb-3">
                      <div>
                        <label className="d-block mb-1 fs-5 fw-bold text-dark me-2">
                          MERCHANT DISCOUNT RATE
                          <sup className="text-danger">*</sup>
                        </label>
                        <label className="fs-6 fw-semibold text-danger mb-1">
                          (Please enter the MDR rate in Percentage.)
                        </label>
                      </div>
                    </div>

                    {MDRTypes?.map((service) => (
                      <div
                        className="flex-grow-1 mb-3"
                        style={{ minWidth: "calc(33.3333% - 10px)" }}
                        key={service.paymentTypeId}
                      >
                        <label className="d-block mb-1 fs-5 fw-bold text-dark">
                          {service.paymentservice}
                          <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="number"
                          name={service.paymentTypeId}
                          className="form-control"
                          value={MDRData[service.paymentTypeId]}
                          onChange={(e) =>
                            handleMDRChange(
                              service.paymentservice,
                              e.target.value,
                              service.paymentTypeId
                            )
                          }
                          required
                        />
                      </div>
                    ))}

                    <div className="col-12 d-flex justify-content-end mt-4">
                      <button
                        className="badge badge-rounded badge-primary mx-2 py-3 px-3"
                        onClick={handleFormSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-2 mb-4">
                    {isPayoutServiceTaken && (
                      <Col xs={12} className="mb-3">
                        <label className="mx-4 text-dark fs-4 mt-2">
                          Payout
                          <sup className="text-danger">*</sup>
                        </label>
                        <label
                          className="fw-semi-bold text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          (Fixed charge per txn)
                        </label>
                      </Col>
                    )}
                    {DefaultServicesList?.map(
                      (service) =>
                        isPayoutServiceTaken && (
                          <div
                            className="flex-grow-1 mb-3"
                            style={{ minWidth: "calc(33.3333% - 10px)" }}
                            key={service.paymentTypeId}
                          >
                            <label className="d-block mb-1 fs-5 fw-bold text-dark">
                              {/* {defaultPayoutServicesMDR[service.name]} */}
                              {service.paymentService}
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="number"
                              name={service.name}
                              className="form-control"
                              value={defaultPayoutServicesMDR[service.name]}
                              onChange={(e) =>
                                handleDefaultServicesMDRChange(
                                  e.target.name,
                                  service.paymentService,
                                  e.target.value,
                                  service.paymentTypeId
                                )
                              }
                              required
                            />
                          </div>
                        )
                    )}
                  </div>

                  {isPayoutServiceTaken && (
                    <div className="d-flex justify-content-end mt-4">
                      <button
                        className="badge badge-rounded badge-primary mx-2 py-3 px-3"
                        onClick={handleDefaultServicesMDRsubmit}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MDRForm;
