import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
// import DailyTransactionBox from "./DailyTransactionBox";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ViewResponse from "./ViewResponse";
import ReduceCapacityTwoToneIcon from "@mui/icons-material/ReduceCapacityTwoTone";
// import { MDBDataTable } from "mdbreact";
import Pagination from "@mui/material/Pagination";
import DownloadCSV from "./DownloadCSV";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import useApiServices from "../../utils/useApiServices";

import {
  setamount,
  setbankLocation,
  setcustomerLocation,
  setdate,
  setendAmount,
  setendDate,
  setipAddress,
  setisFilterUsed,
  setmid,
  setpayerVPA,
  setpaymentType,
  setsid,
  setstartAmount,
  setstartDate,
  settransactionStatus,
} from "../../store/slices/FilterSlice";
import {
  GET_ALL_SID,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX,
  GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID,
  GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID,
  GET_REPORT_API,
  GET_RESPONSE_CODE,
  LIVE_PAYIN_MORE_FILTER_API,
  PAYIN_FILTER_API,
} from "../../utils/constant";
import Alert from "react-bootstrap/Alert";
import FloatingReports from "../../common/FlotingReports";
import MerchantIcon from "../../common/MerchantIcon";
import DownloadCsv from "../../common/DownloadCsv";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { errorCodeDescrition, errorCodes } from "../../utils/ConstantKeys";
import { useNavigate } from "react-router-dom";
import Shimmer from "../../common/Shimmer/Shimmer";
import SlideBox from "../../common/SlideBox/SlideBox";
import swal from "sweetalert";
import Filters from "./Filters";
import {
  convertDateFormat,
  convertTime,
  getTimeWithoutMillisecondsAndDate,
  paginationCountCalc,
} from "../../utils/helper";
import NewDowloadCSV from "./NewDownloadCSV";
import DataNotFound from "../../common/DataNotFound/DataNotFound";
import axios from "axios";

const LivePayinReport = ({ toggleRightBar, type }) => {
  const [viewD, setViewDetailsCallbackResponse] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [mid, setMid] = useState("");
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPostForQueryParams } =
    useApiServices();
  const [dropDownData, setDropDownData] = useState([]);
  const [data, setData] = useState([]);
  const [downloadCSVData, setDownloadCSVData] = useState([]);
  const [show2, setShow2] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [showMultipleFilter, setShowMultipleFilter] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [reset, setReset] = useState(false);
  const [merchantID, setMerchantID] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataM, setTableDataM] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [SIDData, setSIDData] = useState([]);
  const [count, setCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [showLoader, setShowLoader] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [condition, setCondition] = useState("1 day");
  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(0);
  const [showServerCallBack, setShowServerCallBack] = useState([]);
  const [showCallBack, setShowCallBack] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const entriesPerPageOptions = [50, 100, 200, 300, 400, 500];
  const [changeCount, setChangeCount] = useState(0);
  const [successAmout, setSuccessAmount] = useState("");
  const [successData, setSuccessData] = useState("");
  const [txnSize, setTxnSize] = useState(0);
  const [txnSizeM, setTxnSizeM] = useState(0);
  const [resetCalled, setResetCalled] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [company, setCompany] = useState(null);
  const [m, setM] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [totalTransactionCount, setTotalTransactionCount] = useState("");
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [isAdvance, setIsAdvance] = useState(false);

  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
      if (response?.statusCode === 200) {
        setDropDownData(response?.data);
      } else {
        setDropDownData([]);
      }
    } catch (error) {
      setDropDownData([]);
    }
  };

  const getSIDData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_SID);
      if (response?.statusCode === 200) {
        setSIDData(
          response?.data?.map((item) => {
            return {
              sid: item.sid,
              mid: item.mid,
              vehicleName: item.vehicleName,
              prifix: item.prifix,
              company: item.company,
            };
          })
        );
      } else {
        setSIDData([]);
      }
    } catch (error) {
      setDropDownData([]);
    }
  };
  useEffect(() => {}, [mid, setMid]);

  // useEffect(() => {
  //   getSIDData();
  //   getMerchantData();
  //   setTransactionDetails([]);
  // }, [page, condition]);

  const navigate = useNavigate();

  const HandleShowResponseCode = async (id) => {
    console.log(id);

    try {
      const response = await apiCallFnforGet(
        GET_RESPONSE_CODE + id?.split("-")[1],
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      console.log(response);

      if (response?.statusCode === 200) {
        let alertColorClass;

        if (
          response?.data?.includes(205) ||
          response?.data?.includes(206) ||
          response?.data?.includes(207)
        ) {
          alertColorClass = "alert alert-success"; // Green color for 205, 206, 207
        } else {
          alertColorClass = "alert alert-danger"; // Red color for others
        }

        // Create the alert element manually
        const alertElement = document.createElement("div");
        alertElement.className = alertColorClass;
        alertElement.role = "alert";
        alertElement.innerText = response?.data;

        swal({
          title: "Response Code",
          content: alertElement, // Pass the DOM element directly
          className: "custom-swal-popup",
        });
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        });
        // navigate('/Reports')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ChangedPageIncrement = () => {
    {
      changeCount > 0 && setChangeCount(changeCount + 1);
    }
  };

  useEffect(() => {
    console.log(
      "csvData-------------------------------------------------",
      downloadCSVData
    );
  }, [downloadCSVData]);

  const dispatch = useDispatch();

  const getDailyTransactionData = () => {
    apiCallFnforPost(GET_ALL_DAILY_TRANSACTION_DATA_BOX, {
      mid: mid,
      timeFrame: condition,
    })
      .then((response) => {
        if (response.statusCode === 200) {
          const sortedData = response.data.sort((a, b) => {
            if (a.evokTxnCode === "Success") return -1;
            if (b.evokTxnCode === "Success") return 1;
            return 0;
          });

          setData(sortedData);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setData([]);
      });
  };

  const filterData = useSelector((state) => state);

  const withOrWithoutMid = (mid) => {
    if (mid === "") {
      return true;
    } else {
      return false;
    }
  };

  // console.log("here",getTimeWithoutMillisecondsAndDate("2024-07-10 15:49:55.2"))

  const getDailyTransactionDataTable = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforPost(
        `${GET_REPORT_API}?size=${size}&page=${page - 1}`,
        {
          mid: mid,
          reportType: "Payin",
          timeFrame: condition,
        }
      );
      if (response.statusCode === 200) {
        // setTableData(response?.data);
        setShowLoader(false);
        setSuccessAmount(
          response?.duplicateData?.sumOfSuccessAmount ||
            response?.duplicateData?.sumOfSuccessAmountSL
        );
        setSuccessData(response?.duplicateData);
        setTxnSize(
          paginationCountCalc(
            response?.duplicateData?.txnCount ||
              response?.duplicateData?.totaltxn
          )
        );
        setTotalTransactionCount(response?.duplicateData?.txnCount);
        setSizeOfTransactions(response?.duplicateData?.txnCount);
        const finalRes = response?.data?.map((item, i) => ({
          ...item,
          id: i,
          companyName: item.companyName,
          mid: item.mid,
          sid: item.sid,
          txnStatus: item.txnStatus,
          txnRespCode: item.txnRespCode,
          evokTxnCode: item.evokTxnCode,
          paymentType: item.paymentType,
          orderNo: item.orderNo,
          txn_id: item.txn_id,
          txnDate: convertDateFormat(item.txnDate?.split(" ")[0]) || "",
          txnTime: item.txnDate?.split(" ")[1] || "",
          sentToBankDate: item?.sentToBankDate?.split(" ")[1] || "",
          ackFromBankDate: item?.ackFromBankDate?.split(" ")[1] || "",
          qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1] || "",
          responseDate: item?.responseDate?.split(" ")[1] || "",
          callbackSentToMerchantDate:
            item?.callbackSentToMerchantDate?.split(" ")[1] || "",
          ackReceivedFromMerchantDate:
            item?.ackReceivedFromMerchantDate?.split(" ")[1] || "",
          responseCode: item.responseCode,
          terminalId: item.terminalid,
          amount: item.amount,
          currency: item.currency,
          custRefNo: item.custRefNo,
          userVpa: item.userVpa,
          callbackRespJson: (
            <button
              className=" badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() =>
                ViewDetailsCallbackResponse(
                  item.callbackRespJson,
                  item.companyName,
                  item.mid
                )
              }
            >
              View
            </button>
          ),
          serverRespJson: (
            <button
              className=" badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() =>
                ViewDetailsCallbackResponse(
                  item.serverRespJson,
                  item.companyName,
                  item.mid
                )
              }
            >
              View
            </button>
          ),
          ShowResponseCode: (
            <button
              className=" badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() => HandleShowResponseCode(item.txn_id)}
            >
              View
            </button>
          ),
          mobileNo: item.mobileNo,
          email: item.email,
          customerIp: item.customerIp,
          customerLocation: item.customerLocation,
          remark: item.remark,
          customerIfsc: item.customerIfsc,
          bankLocation: item.bankLocation,
        }));

        setTableData(finalRes);
        setTableDataM([]);
        setCount(response.size);
        setTransactionDetails(response.size);
        // setShowLoader(false);
      } else {
        setTableData([]);
        setSuccessAmount("");
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // setShowLoader(false);
    }
  };

  if (viewD) {
    ViewDetailsCallbackResponse(viewD);
  }

  const ViewDetailsCallbackResponse = (item, name, m) => {
    setAlertData(item);
    setCompany(name);
    setM(m);
    setShowResponse(true);
  };

  useEffect(() => {
    setDownloadCSVData(tableData);
  }, [tableData]);

  const getDailyTransactionDataByMId = (merchantID) => {
    const response = apiCallFnforGet(
      GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID + "/" + merchantID
    )
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setMerchantID("");
        } else {
          setData([]);
          // console.error("Error fetching data:", response.data.message);
        }
        setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(!showAdvanceFilter);
  };

  const handleMultipleFilter = () => {
    setShowMultipleFilter(true);
  };

  const DispatcherFunction = () => {
    dispatch(setamount(0));
    dispatch(setbankLocation(""));
    dispatch(setcustomerLocation(""));
    dispatch(setdate(""));
    dispatch(setendAmount(0));
    dispatch(setendDate(""));
    dispatch(setipAddress(""));
    dispatch(setmid(""));
    dispatch(setpayerVPA(""));
    dispatch(setpaymentType(""));
    dispatch(setstartAmount(0));
    dispatch(setstartDate(""));
    dispatch(setsid(""));
    dispatch(settransactionStatus(""));
    dispatch(setisFilterUsed(false));
  };

  const HandleReset = async () => {
    // DispatcherFunction();
    setReset(!reset);
    // getDailyTransactionDataTable();
    // getDailyTransactionData();
    setMid("");
    setCondition("1 day");
    setPage(1);
    setPageM(1);
    setTableDataM([]);
    setIsAdvance(false);
    setShowAdvanceFilter(false);
  };

  // useEffect(() => {
  //   getDailyTransactionData();
  // }, [reset, currentPage, entriesPerPage, mid, condition]);

  // useEffect(() => {
  //   getSIDData();
  //   getMerchantData();
  //   setTransactionDetails([]);
  // }, [page, condition]);

  const debouncedMiscellenious = useCallback(
    debounce(() => {
      getSIDData();
      // getMerchantData();
      setTransactionDetails([]);
    }, 0),
    [page, condition]
  );

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);

  const debouncedGetDailyTransactionDataTable = useCallback(
    debounce(() => {
      getDailyTransactionData();
      getDailyTransactionDataTable();
    }, 0),
    [currentPage, condition, mid, page, reset, entriesPerPage]
  );
  useEffect(() => {
    debouncedGetDailyTransactionDataTable();
  }, [debouncedGetDailyTransactionDataTable]);

  // useEffect(() => {

  //   getDailyTransactionDataTable();

  // }, [
  //   currentPage,
  //   condition,
  //   setCondition,
  //   mid,
  //   setMid,
  //   changeCount,
  //   setChangeCount,
  //   page,
  //   setSuccessAmount,
  //   successAmout,
  //   reset,
  //   entriesPerPage

  // ]);

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const handleServerShowOrNot = (index) => {
    setShowServerCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const columns = [
    { headerName: "MID", field: "mid", minWidth: "200" },
    { headerName: "Company Name", field: "companyName", minWidth: "220" },
    { headerName: "SID", field: "sid", minWidth: "200" },
    { headerName: "Payment Type", field: "paymentType", minWidth: "150" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "200" },
    { headerName: "Order Number", field: "orderNo", minWidth: 320 },
    { headerName: "Transaction ID", field: "txn_id", minWidth: 270 },
    { headerName: "Amount", field: "amount", minWidth: "150" },
    { headerName: "Transaction Date", field: "txnDate", minWidth: "200" },
    { headerName: "Request Time", field: "txnTime", minWidth: "200" },
    { headerName: "Sent to Bank", field: "sentToBankDate", minWidth: "200" },
    {
      headerName: "Response from Bank",
      field: "ackFromBankDate",
      minWidth: "200",
    },
    {
      headerName: "QR Sent to Merchant",
      field: "qrSentToMerchantDate",
      minWidth: "200",
    },
    {
      headerName: "Callback Received from Bank",
      field: "responseDate",
      minWidth: "200",
    },
    {
      headerName: "Callback Sent to Merchant",
      field: "callbackSentToMerchantDate",
      minWidth: "200",
    },
    {
      headerName: "Ack from Merchant",
      field: "ackReceivedFromMerchantDate",
      minWidth: "200",
    },
    { headerName: "Currency", field: "currency", minWidth: 150 },
    { headerName: "UTR Number", field: "custRefNo", minWidth: "200" },
    { headerName: "Payer VPA", field: "userVpa", minWidth: "200" },
    { headerName: "Mobile Number", field: "mobileNo", minWidth: 160 },
    { headerName: "e-Mail", field: "email", minWidth: 180 },
    {
      headerName: "Transaction Response Status",
      field: "txnRespCode",
      minWidth: "200",
    },
    {
      headerName: "Bank Response Code",
      field: "evokTxnCode",
      minWidth: "200",
    },
    { headerName: "Remark Descripation", field: "remark", minWidth: "200" },
    { headerName: "Customer IFSC", field: "customerIfsc", minWidth: "200" },
    { headerName: "Customer IP Address", field: "customerIp", minWidth: "200" },
    {
      headerName: "Customer Location",
      field: "customerLocation",
      minWidth: "200",
    },
    // { headerName: "Merchant Response", field: "responseCode", minWidth: "200" },
    { headerName: "Terminal ID", field: "terminalId", minWidth: "200" },
    { headerName: "Bank Location", field: "bankLocation", minWidth: "200" },
    { headerName: "Vehicle Name", field: "vehicleName", minWidth: "200" },
    {
      headerName: "Date Of Registration",
      field: "date_of_reg",
      minWidth: "200",
    },
    { headerName: "User Id", field: "user_id", minWidth: "200" },
    {
      headerName: "Callback Response",
      field: "callbackRespJson",
      minWidth: "200",
    },
    {
      headerName: "Server Response Json",
      field: "serverRespJson",
      minWidth: "200",
    },
    {
      headerName: "Response Code",
      field: "ShowResponseCode",
      minWidth: "350",
    },
  ];

  const mappingFunction = (tableData) => {
    const returnMap = tableData?.map((item, index) => {
      return {
        ...item,

        evokTxnCode: item.txnStatus === "success" ? "0" : item.evokTxnCode,
        sentToBankDate: item?.sentToBankDate?.split(" ")[1],
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1],
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1],

        responseDate: item?.responseDate?.split(" ")[1],
        serverRespJson: item?.serverRespJson ? (
          <>
            <Button
              className="mb-2"
              onClick={() => handleServerShowOrNot(index)}
            >
              {showServerCallBack[index] ? "Hide" : "Show"}
            </Button>
            <div>{showServerCallBack[index] ? item.serverRespJson : null}</div>
          </>
        ) : null,
        callbackRespJson: item.callbackRespJson ? (
          <>
            <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
              {showCallBack[index] ? "Hide" : "Show"}
            </Button>
            <div>{showCallBack[index] ? item.callbackRespJson : null}</div>
          </>
        ) : null,
      };
    });
    return returnMap;
  };

  const MappedData = mappingFunction(tableData);

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = data.filter(
      (item) => item.txnStatus.toUpperCase() === "SUCCESS"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(tableData);

  const paginationfunction = (d) => {
    console.log("chal rha", d);
  };

  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

  // function CustomToolbar() {
  //   const openModal = () => {
  //     swal(
  //       "Maximum Transactions Limit Exceeds, Plz Download From Archieve Reports.",
  //       {
  //         buttons: {
  //           cancel: "Cancel",
  //           confirm: {
  //             text: "OK",
  //             value: "ok",
  //           },
  //         },
  //       }
  //     ).then((value) => {
  //       if (value === "ok") {
  //         swal.close();
  //         // 1 is stand for archieve report
  //         navigate("/Reports/ArchivedReports");
  //       }
  //     });
  //   };

  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarColumnsButton />
  //       <GridToolbarFilterButton />
  //       <GridToolbarDensitySelector
  //         slotProps={{ tooltip: { title: "Change density" } }}
  //       />
  //       {txnSize > 2000 ? (
  //         <div
  //           onClick={openModal}
  //           style={{ color: "#F6FAFD" }}
  //           className="d-flex align-items-center gap-2"
  //         >
  //           {" "}
  //           <span
  //             style={{ color: "#3C76D2", fontWeight: "600" }}
  //             className="mb-1"
  //           >
  //             {/* <i class="bi bi-download"></i> */}
  //             <svg
  //               xmlns="http://www.w3.org/2000/svg"
  //               width="16"
  //               height="16"
  //               fill="currentColor"
  //               class="bi bi-download fw-bold"
  //               viewBox="0 0 16 16"
  //             >
  //               <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
  //               <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
  //             </svg>
  //           </span>
  //           <span style={{ color: "#3C76D2", fontWeight: "400" }}>EXPORT </span>
  //         </div>
  //       ) : (
  //         <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
  //       )}
  //       {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

  //       <Box sx={{ flexGrow: 1 }} />
  //       <div class="row">
  //         <div class="col text-end">
  //           <span class="m-b-0 me-2 fs-18 text-success">
  //             Success amount :<span className="mx-2">{successAmout}</span>
  //           </span>
  //         </div>
  //       </div>
  //     </GridToolbarContainer>
  //   );
  // }

  // second one
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div className="d-flex justify-content-center gap-2">
            <GridToolbarExport className="badge badge-sm badge-primary text-white fw-bold" />
            <div
              onClick={() => setShow(true)}
              style={{ color: "#F6FAFD", cursor: "pointer", fontSize: "13px" }}
              className="d-flex align-items-center gap-1"
            >
              {" "}
              <span
                style={{ color: "#3C76D2", fontWeight: "900" }}
                className="mb-1"
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#ff6e33"
                  class="bi bi-download fw-bold"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg> */}
              </span>
              {/* <span style={{ color: "#ff6e33", fontWeight: "500" }}>
                EXPORT {">"} 100
              </span> */}
            </div>
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start">
              <GridToolbarQuickFilter />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getWarning = async (status, mid) => {
    // alert(mid);
    try {
      const payload = {
        amount: 0,
        bankLocation: "",
        customerLocation: "",
        date: "",
        endAmount: 0,
        endDate: "",
        greterAmount: 0,
        ipAddress: "",
        lessAmount: 0, // Include mobile value
        mid: mid ? mid : "",
        payerVpa: "",
        paymentType: "",
        sid: "",
        startAmount: 0,
        startDate: "",
        transactionStatus: status,
        txnresponseCode: 0,
      };
      setShowLoader(true);
      const response = await axios.post(
        mid === "" ? LIVE_PAYIN_MORE_FILTER_API : PAYIN_FILTER_API,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
          params: {
            page: pageM - 1,
            size: sizeM,
          },
        }
      );

      console.log(response);
      //  await setShowLoader(false);

      if (response.data.statusCode === 200) {
        // setSuccessAmount(response?.duplicateData?.sumOfSuccessAmount || response?.duplicateData?.sumOfSuccessAmountSL);
        const finalRes = response?.data?.responseData?.map((item, i) => ({
          ...item,
          id: i,
          companyName: item.companyName,
          mid: item.mid,
          sid: item.sid,
          txnStatus: item.txnStatus,
          txnRespCode: item.txnRespCode,
          evokTxnCode: item.evokTxnCode,
          paymentType: item.paymentType,
          orderNo: item.orderNo,
          txn_id: item.txn_id,
          txnDate: convertDateFormat(item.txnDate?.split(" ")[0]) || "",
          txnTime: item.txnDate?.split(" ")[1] || "",
          sentToBankDate: item?.sentToBankDate?.split(" ")[1] || "",
          ackFromBankDate: item?.ackFromBankDate?.split(" ")[1] || "",
          qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1] || "",
          responseDate: item?.responseDate?.split(" ")[1] || "",
          callbackSentToMerchantDate:
            item?.callbackSentToMerchantDate?.split(" ")[1] || "",
          ackReceivedFromMerchantDate:
            item?.ackReceivedFromMerchantDate?.split(" ")[1] || "",
          responseCode: item.responseCode,
          terminalId: item.terminalid,
          amount: item.amount,
          currency: item.currency,
          custRefNo: item.custRefNo,
          userVpa: item.userVpa,
          callbackRespJson: (
            <button
              className="badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() =>
                ViewDetailsCallbackResponse(
                  item.callbackRespJson,
                  item.companyName,
                  item.mid
                )
              }
            >
              View
            </button>
          ),
          serverRespJson: (
            <button
              className="badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() =>
                ViewDetailsCallbackResponse(
                  item.serverRespJson,
                  item.companyName,
                  item.mid
                )
              }
            >
              View
            </button>
          ),
          ShowResponseCode: (
            <button
              className=" badge badge-rounded badge-info mx-2 px-3 py-2"
              onClick={() => HandleShowResponseCode(item.txn_id)}
            >
              View
            </button>
          ),
          mobileNo: item.mobileNo,
          email: item.email,
          customerIp: item.customerIp,
          customerLocation: item.customerLocation,
          remark: item.remark,
          customerIfsc: item.customerIfsc,
          bankLocation: item.bankLocation,
        }));
        console.log("This is finalres---------->", finalRes);
        const getDateNDaysAgo = (n) => {
          const date = new Date();
          date.setDate(date.getDate() - n);
          return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        };

        const threeDaysAgo = getDateNDaysAgo(3);

        const filteredRes = finalRes?.filter(
          (item) => item.txnDate >= threeDaysAgo
        );

        if (filteredRes.length === 0) {
          swal("Alert!", "No Data Found", "error");
        } 
        // alert(filteredRes.length);
        setTableDataM(filteredRes.length ? filteredRes : []);
        setShowLoader(false);
        // setTransactionReportData(response?.data?.responseData);
      } else {
        swal("Alert!", "No Data Found", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeM = (event, value) => {
    setPageM(value);
  };
  return (
    <>
      <Alert show={showAlert} variant="info">
        <Alert.Heading>RESPONSE</Alert.Heading>
        <p>{alertData}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShowAlert(false)} variant="outline-danger">
            Close me
          </Button>
        </div>
      </Alert>

      {/* <div className="content-body"> */}
      <div className="container-fluid">
        <MerchantIcon setMid={setMid} reset={reset} />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="col-12">
                {/* <div className="card-body"> */}
                <section className="lincpay-dashboard-sec">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        class="badge badge-danger m-3 fs-5 cursor-pointer"
                        onClick={() => getWarning("warning", mid)}
                      >
                        Warning
                      </button>

                      <div
                        className="fs-5 badge badge-danger cursor-pointer"
                        onClick={() => getWarning("blacklist", mid)}
                      >
                        Blacklist
                      </div>
                    </div>
                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"reset"}>
                        <i className="fa fa-refresh" onClick={HandleReset}></i>
                      </IconWrapper>
                      <IconWrapper title={"Multiple Filter"}>
                        <i
                          class="fa fa-filter"
                          onClick={handleAdvanceFilter}
                        ></i>
                      </IconWrapper>
                    </div>
                  </div>

                  <div className="dasboard-view-count-boxes">
                    <Card.Body>
                      <div className="report-grid-view">
                        <div
                          className="material-table-records mb-3 "
                          style={{ height: "500px" }}
                        >
                          {showLoader ? (
                            <Shimmer type="table" />
                          ) : showAdvanceFilter &&
                            isAdvance &&
                            tableDataM.length === 0 ? (
                            <div className="no-data-found">
                              No data found for the selected filters.
                            </div>
                          ) : !showAdvanceFilter &&
                            tableData.length === 0 &&
                            tableDataM.length === 0 ? (
                            // <div className="no-data-found">No data found.</div>
                            <DataNotFound />
                          ) : !tableDataM?.length ? (
                            <>
                              <DataGrid
                                rows={tableData}
                                columns={columns.map((column) => ({
                                  ...column,
                                  renderCell: (params) =>
                                    column.field === " callbackRespJson" ||
                                    column.field === "serverRespJson" ||
                                    column.field === "ShowResponseCode"
                                      ? params.value
                                      : params.value,
                                }))}
                                slots={{
                                  toolbar: CustomToolbar,
                                }}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  },
                                }}
                                hideFooterPagination={true}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0.8rem",
                                }}
                              >
                                <Pagination
                                  count={txnSize}
                                  page={page}
                                  onChange={handleChange}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <DataGrid
                                rows={tableDataM}
                                columns={columns.map((column) => ({
                                  ...column,
                                  renderCell: (params) =>
                                    column.field === " callbackRespJson" ||
                                    column.field === "serverRespJson" ||
                                    column.field === "ShowResponseCode"
                                      ? params.value
                                      : params.value,
                                }))}
                                slots={{
                                  toolbar: CustomToolbar,
                                }}
                                // className="mb-1 text-dark fw-bold"
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  },
                                }}
                                hideFooterPagination={true}
                                // onPaginationModelChange={paginationfunction}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "0.8rem",
                                }}
                              >
                                <Pagination
                                  count={txnSizeM}
                                  page={pageM}
                                  onChange={handleChangeM}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center"></div>
                      </div>
                    </Card.Body>
                  </div>
                </section>
                {/* New error code */}
                <div className="card-body mt-4">
                  <div>
                    <div style={{ minHeight: "90px", height: "60px" }}>
                      <div className="d-flex gap-2">
                        <div class="d-flex justify-content-start overflow-auto">
                          <ul class="list-unstyled d-flex py-3 gap-2 flex-nowrap">
                            {data?.length !== 0 &&
                              data?.map((errcode, i) => {
                                return (
                                  <li
                                    key={i + 1}
                                    style={{
                                      color: "black",
                                      backgroundColor:
                                        errorCodes[errcode?.evokTxnCode],
                                    }}
                                    class="badge badge-rounded badge-primary d-flex align-items-center"
                                  >
                                    {`${errcode?.evokTxnCode} : ${
                                      errcode?.percentage ?? 0
                                    }%`}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="d-flex align-items-center py-3 mb-3">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#bd-example-modal-lg"
                            class="badge badge-rounded badge-primary"
                          >
                            More
                          </a>
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="bd-example-modal-lg"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Error codes</h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>
                            <div
                              className="modal-body"
                              style={{ padding: "0px" }}
                            >
                              <div className="table-responsive">
                                <table
                                  className="table table-responsive-md"
                                  style={{ padding: "0px auto" }}
                                >
                                  <thead style={{ padding: "0px auto" }}>
                                    <tr style={{ padding: "0px auto" }}>
                                      <th style={{ width: "80px" }}>
                                        <strong>Error code</strong>
                                      </th>
                                      <th>
                                        <strong>Count</strong>
                                      </th>
                                      <th>
                                        <strong>Percentage</strong>
                                      </th>
                                      <th>
                                        <strong>Description</strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.length !== 0 &&
                                      data?.map((errcode) => {
                                        return (
                                          <tr style={{ padding: "0px auto" }}>
                                            <td style={{ padding: "0px auto" }}>
                                              <span
                                                className="badge light badge-success"
                                                style={{
                                                  backgroundColor:
                                                    errorCodes[
                                                      errcode?.evokTxnCode
                                                    ],
                                                  color: "#000",
                                                }}
                                              >
                                                {errcode?.evokTxnCode}
                                              </span>
                                            </td>
                                            <td> {errcode?.count}</td>
                                            <td>{errcode?.percentage}%</td>
                                            <td>
                                              {
                                                errorCodeDescrition[
                                                  errcode?.evokTxnCode
                                                ]
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>

                              <div className="card">
                                <div className="card-body">
                                  <div className="col-md-12">
                                    <div className="card-body d-flex ">
                                      <div className="me-auto">
                                        <h4 className="card-title">
                                          Total Transactions
                                        </h4>
                                        <div className="d-flex align-items-center">
                                          <h2 className="fs-38 mb-0">
                                            {totalTransactionCount}
                                            {/* {successData
                                                ? successData?.txnCount
                                                : successData?.totaltxn} */}
                                          </h2>
                                        </div>
                                      </div>
                                      <div className="text-center">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="m-b-0 text-success">
                                              {successData
                                                ? successData?.successCount
                                                : ""}
                                            </h3>
                                            <span className="text-success">
                                              Success
                                            </span>
                                          </div>
                                          <div className="col">
                                            <h3 className="m-b-0 text-danger">
                                              {successData
                                                ? successData?.failureCount
                                                : ""}
                                            </h3>
                                            <span className="text-danger">
                                              Failed
                                            </span>
                                          </div>
                                          <div className="col">
                                            <h3 className="m-b-0 text-warning">
                                              {successData
                                                ? successData?.pendingCount
                                                : ""}
                                            </h3>
                                            <span className="text-warning">
                                              Pending
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger light"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {showAdvanceFilter ? (
                  <Filters
                    setTransactionReportData={setTableDataM}
                    setIsAdvanceFilterOpen={setShowAdvanceFilter}
                    //Multiple filter props
                    setShowMultipleFilter={setShowMultipleFilter}
                    dropDownData={dropDownData}
                    setLiveBoxData={setData}
                    setDownloadCSVData={setDownloadCSVData}
                    SIDData={SIDData}
                    type={type}
                    mid={mid}
                    sizeM={sizeM}
                    pageM={pageM}
                    ViewDetailsCallbackResponse={ViewDetailsCallbackResponse}
                    setShowLoader={setShowLoader}
                    setSuccessAmount={setSuccessAmount}
                    setTxnSizeM={setTxnSizeM}
                    setSuccessData={setSuccessData}
                    setTotalTransactionCount={setTotalTransactionCount}
                    setIsAdvance={setIsAdvance}
                    HandleShowResponseCode={HandleShowResponseCode}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showResponse && (
        <ViewResponse
          setShowResponse={setShowResponse}
          alertData={alertData}
          m={m}
          company={company}
        />
      )}

      {/* </div> */}
      <FloatingReports
        type="reports"
        setCondition={setCondition}
        mid={mid}
        setData={setData}
        propToReceive={resetCalled}
        reset={reset}
      />

      {/* Download CSv */}
      {show === true ? (
        <NewDowloadCSV type="payin" show={show} setShow={setShow} />
      ) : null}
    </>
  );
};

export default LivePayinReport;
