import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Row, Offcanvas, Button, Modal } from "react-bootstrap";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DownloadCsv from "../../common/DownloadCsv";
import MidFiter from "./MidFiter";
import { useNavigate, useParams } from "react-router-dom";
import { getAllDailyTransactionDataPayout, HandleStatusUpdate } from "../../utils/ApiServices";
import LivePayoutMultipleFilter from "./LivePayoutMultipleFilter";
import LivePayoutAdvanceFilter from "./LivePayoutAdvanceFilter";
import FloatingReports from "../../common/FlotingReports";
import useApiServices from "../../utils/useApiServices";
import { GET_REPORT_API } from "../../utils/constant";
import MerchantIcon from "../../common/MerchantIcon";
import IconWrapper from "../../common/IconWrapper/IconWrapper";
import { Box } from "@mui/material";
import Shimmer from "../../common/Shimmer/Shimmer";
import Pagination from "@mui/material/Pagination";
import Filters from "./Filters";
import { debounce } from "lodash";
import {
  convertDateFormat,
  convertTime,
  getTimeWithoutMillisecondsAndDate,
  paginationCountCalc,
} from "../../utils/helper";
import NewDowloadCSV from "./NewDownloadCSV";
import DataNotFound from "../../common/DataNotFound/DataNotFound";
import ViewResponse from "./ViewResponse";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const Payout = ({ dropDownData, reportType, type, toggleRightBar }) => {
  const { apiCallFnforPostForQueryParams } = useApiServices();
  const [condition, setCondition] = useState("1 day");
  const [mid, setMid] = useState("");
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [show2, setShow2] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [showCallBack, setShowCallBack] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [5, 10, 20, 30, 40, 50];
  const [changeCount, setChangeCount] = useState(0);
  const [zoomCount, setZoomCount] = useState(16);
  const [showLoader, setShowLoader] = useState(true);
  const [successAmout, setSuccessAmount] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageM, setPageM] = useState(1);
  const [sizeM, setSizeM] = useState(100);
  const [txnSize, setTxnSize] = useState(0);
  const [txnSizeM, setTxnSizeM] = useState(0);
  const [show, setShow] = useState(false);
  const [sizeOfTransactions, setSizeOfTransactions] = useState(0);
  const [showResponse, setShowResponse] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [m, setM] = useState(null);
  const [company, setCompany] = useState(null);

 

  const getDailyTransactionData = async () => {
    try {
      setShowLoader(true);
      await apiCallFnforPostForQueryParams(
        GET_REPORT_API,
        {
          mid: mid,
          reportType: "Payout",
          timeFrame: condition,
        },

        {
          page: page - 1,
          size,
        }
      ).then((response) => {
        if (response.statusCode === 200) {
          setSuccessAmount(
            response?.duplicateData?.sumOfSuccessAmount ||
              response?.duplicateData?.sumOfSuccessAmountSL
          );
          setTxnSize(
            paginationCountCalc(
              response?.duplicateData?.txnCount ||
                response?.duplicateData?.totaltxn
            )
          );
          setSizeOfTransactions(response?.duplicateData?.txnCount);
          const finalres = response?.data?.map((item, i) => ({
            id: i,
            mid: item.mid,
            companyName: item.companyName,
            createDate: item.createDate,
            txnRequestTime: item?.txnRequestTime?.split(" ")[1] || "",
            txnResponseTime: item?.txnResponseTime?.split(" ")[1] || "",
            callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1] || "",
            callbackSendTime: item?.callbackSendTime?.split(" ")[1] || "",
            merchantcallbackResponseTime:
              item?.merchantcallbackResponseTime?.split(" ")[1] || "",
            callbackJsonReceived: item?.callbackJson
              ? "Received"
              : "Not Received",
            json: (
              <button
                className=" badge badge-rounded badge-info mx-2 px-3 py-2"
                onClick={() =>
                  ViewDetailsCallbackResponse(
                    item.json,
                    item.companyName,
                    item.mid
                  )
                }
              >
                View
              </button>
            ),
            callbackJSON: (
              <button
                className=" badge badge-rounded badge-info mx-2 px-3 py-2"
                onClick={() =>
                  ViewDetailsCallbackResponse(
                    item.callbackJson,
                    item.companyName,
                    item.mid
                  )
                }
              >
                View
              </button>
            ),
            merchantCallbackRecived:
              item?.merchantCallbackRecived === "1"
                ? "Received"
                : "Not Received",
            merchantcallbackResponse: item.merchantcallbackResponse,
            amount: item.amount,
            accountNumber: item.accountNumber,
            ifscCode: item.ifscCode,
            email: item.email,
            mobile: item.mobile,
            reference: item.reference,
            upi: item.upi,
            createTime: item.createTime,
            deductionChargesPerTxn: item.deductionChargesPerTxn,
            rrn: item.rrn,
            txnId: item.txnId,
            transferType: item.transferType,
            paymentBy: item.paymentBy,
            txnStatus: item.txnStatus,
            payoutStatus: item.payoutStatus,
            category: item.category,
            remark:
              item.remark === "SurCharge"
                ? `${item.remark}${" of "}${item.reference.replace("_s", "")}`
                : item.remark,
            action: (
              <select
                className={
                  item.txnStatus === "pending"
                    ? "badge badge-rounded badge-warning"
                    : item.txnStatus === "success"
                    ? "badge badge-rounded badge-success"
                    : "badge badge-rounded badge-danger"
                }
                onChange={(e) =>
                  HandleStatusUpdate(e.target.value, item.reference)
                }
                value={item.txnStatus}
              >
                <option
                  className=" badge badge-rounded badge-success mx-2 px-3 py-2"
                  value="success"
                  // onClick={() => HandleStatusUpdate(item.reference)}
                >
                  SUCCESS
                </option>
                <option
                  className=" badge badge-rounded badge-danger mx-2 px-3 py-2"
                  value="failure"
                  // onClick={() => HandleStatusUpdate(item.reference)}
                >
                  FAILURE
                </option>
                <option
                  className=" badge badge-rounded badge-warning mx-2 px-3 py-2"
                  value="pending"
                  // onClick={() => HandleStatusUpdate(item.reference)}
                >
                  PENDING
                </option>
              </select>
            ),
          }));

          setData(finalres);
          setData2([]);
          // setData2(finalres);
          if (response.statusCode !== 200) {
            setData([]);
          }
          setShowLoader(false);
        } else {
          setData([]);
          setSuccessAmount("");
          setShowLoader(false);

          console.error("Error fetching data:", response.data.message);
        }
      });
    } catch (error) {
      console.error("Error saving data:", error);
      setShowLoader(false);
    }
  };

  const ViewDetailsCallbackResponse = (item, name, m) => {
    setAlertData(item);
    setCompany(name);
    setM(m);
    setShowResponse(!showResponse);
  };

  // useEffect(() => {
  //   getDailyTransactionData();
  // }, [reset, currentPage, entriesPerPage, condition, setCondition, mid, setMid, changeCount, setChangeCount,page]);

  const debouncedMiscellenious = useCallback(
    debounce(() => {
      getDailyTransactionData();
    }, 0),
    [reset, condition, page, mid, entriesPerPage, currentPage]
  );

  useEffect(() => {
    debouncedMiscellenious();
  }, [debouncedMiscellenious]);

  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      getDailyTransactionData();
    }
  };

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData();
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const columns = [
    { headerName: "Merchant ID", field: "mid", minWidth: "170" },
    { headerName: "Company Name", field: "companyName", minWidth: "310" },
    { headerName: "Amount", field: "amount", minWidth: "100" },
    // { headerName: "Charges", field: "deductionChargesPerTxn", minWidth: "100" },
    { headerName: "Transaction Status", field: "txnStatus", minWidth: "130" },
    { headerName: "Created Date", field: "createDate", minWidth: "150" },
    { headerName: "Created Time", field: "createTime", minWidth: "120" },
    { headerName: "Txn Request", field: "txnRequestTime", minWidth: "140" },
    { headerName: "Txn Response", field: "txnResponseTime", minWidth: "150" },
    {
      headerName: "Callback Received",
      field: "callbackReceiveTime",
      minWidth: "150",
    },
    {
      headerName: "Callback Send To Merchant",
      field: "callbackSendTime",
      minWidth: "210",
    },
    {
      headerName: "Callback Acknowledgment Received",
      field: "merchantcallbackResponseTime",
      minWidth: "190",
    },
    {
      headerName: "Callback Json Received from bank",
      field: "callbackJsonReceived",
      minWidth: "210",
    },
    { headerName: "Response Json", field: "json", minWidth: "120" },
    { headerName: "Callback Json", field: "callbackJSON", minWidth: "120" },
    {
      headerName: "Merchant Callback Received",
      field: "merchantCallbackRecived",
      minWidth: "220",
    },
    {
      headerName: "Merchant Callback Response",
      field: "merchantcallbackResponse",
      minWidth: "210",
    },
    { headerName: "Accout Number", field: "accountNumber", minWidth: "150" },
    { headerName: "IFSC Code", field: "ifscCode", minWidth: "150" },
    { headerName: "e-Mail", field: "email", minWidth: "210" },
    { headerName: "Mobile Number", field: "mobile", minWidth: "140" },
    { headerName: "Reference Id", field: "reference", minWidth: "310" },
    { headerName: "VPA / UPI", field: "upi", minWidth: "100" },
    { headerName: "UTR Number", field: "rrn", minWidth: "150" },
    { headerName: "Transaction Id", field: "txnId", minWidth: "220" },
    { headerName: "Transfer Type", field: "transferType", minWidth: "120" },
    { headerName: "Payment By", field: "paymentBy", minWidth: "150" },
    { headerName: "Payout Status", field: "payoutStatus", minWidth: "100" },
    { headerName: "Category", field: "category", minWidth: "100" },
    { headerName: "Remark", field: "remark", minWidth: "250" },
    { headerName: "Action", field: "action", minWidth: "250" },
  ];

  const mappedData = data
    ? data?.map((item, index) => {
        return {
          ...item,
          date: item?.createDate.split(" ")[0],
          time: item?.createDate.split(" ")[1],
          time: item?.createDate.split(" ")[1],
          txnRequestTime: item?.txnRequestTime?.split(" ")[1],
          txnResponseTime: item?.txnResponseTime?.split(" ")[1],
          callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1],
          callbackSendTime: item?.callbackSendTime?.split(" ")[1],
          deductionChargesPerTxn: item?.deductionChargesPerTxn,
          merchantcallbackResponseTime:
            item?.merchantcallbackResponseTime?.split(" ")[1],
          merchantCallbackRecived:
            item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
          callbackJsonReceived: item?.callbackJson
            ? "Received"
            : "Not Received",
          callbackJSON: item?.callbackJson ? (
            <>
              <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
                {showCallBack[index] ? "Hide" : "Show"}
              </Button>
              <div>{showCallBack[index] ? item.callbackJson : null}</div>
            </>
          ) : null,
        };
      })
    : [];

  // const mappedData2 = data2?.map((item, index) => {
  //   return {
  //     ...item,
  //     date: item?.createDate.split(" ")[0],
  //     time: item?.createDate.split(" ")[1],
  //     time: item?.createDate.split(" ")[1],
  //     txnRequestTime: item?.txnRequestTime?.split(" ")[1],
  //     txnResponseTime: item?.txnResponseTime?.split(" ")[1],
  //     callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1],
  //     callbackSendTime: item?.callbackSendTime?.split(" ")[1],
  //     merchantcallbackResponseTime:
  //       item?.merchantcallbackResponseTime?.split(" ")[1],
  //     merchantCallbackRecived:
  //       item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
  //     callbackJsonReceived: item?.callbackJson ? "Received" : "Not Received",
  //     callbackJSON: item?.callbackJson ? (
  //       <>
  //         <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
  //           {showCallBack[index] ? "Hide" : "Show"}
  //         </Button>
  //         <div>{showCallBack[index] ? item.callbackJson : null}</div>
  //       </>
  //     ) : null,
  //   };
  // });

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = data?.filter(
      (item) => item?.txnStatus?.toUpperCase() === "SUCCESS"
    );
    return successTransactions?.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(data2);

  const HandleReset = async () => {
    // DispatcherFunction();
    setReset(!reset);
    setMid("");
    setCondition("1 day");
    setPage(1);
    setPageM(1);
    setData2([]);
    setIsAdvance(false);
    setIsAdvanceFilterOpen(false);
    // getDailyTransactionData();
  };

  // const HandleReset = () => {
  //   setCondition("1 day");
  //   setReset(!reset);
  //   setMid("");

  //   setPageM(1);
  //   setPage(1);
  //   getDailyTransactionData()
  //   // setIsAdvanceFilterOpen(false);
  //   setIsAdvance(false);
  // };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };

  useEffect(() => {
    setData2([]);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [condition, mid]);

  const incrementZoom = () => {
    {
      zoomCount < 28 && setZoomCount(zoomCount + 1);
    }
  };

  const decrementZoom = () => {
    {
      zoomCount > 14 && setZoomCount(zoomCount - 1);
    }
  };

  const handleClose = () => setShow(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        {sizeOfTransactions > 100 ? (
          <div className="d-flex justify-content-center gap-2">
            <GridToolbarExport className="badge badge-sm badge-primary text-white fw-bold" />
            <div
              onClick={() => setShow(true)}
              style={{ color: "#F6FAFD", cursor: "pointer", fontSize: "13px" }}
              className="d-flex align-items-center gap-1"
            >
              {" "}
              <span
                style={{ color: "#3C76D2", fontWeight: "900" }}
                className="mb-1"
              >
                {/* <i class="bi bi-download"></i> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="#ff6e33"
                  class="bi bi-download fw-bold"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg> */}
              </span>
              {/* <span style={{ color: "#ff6e33", fontWeight: "500" }}>
                EXPORT {">"} 100
              </span> */}
            </div>
          </div>
        ) : (
          <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" />
        )}
        {/* <GridToolbarExport className="badge badge-sm badge-primary text-white fw-semi-bold" /> */}

        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              Success amount :<span className="mx-2">{successAmout}</span>
            </span>
            <div className="d-flex justify-content-start">
              <GridToolbarQuickFilter />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeMultipleFilter = (event, value) => {
    setPageM(value);
  };
  return (
    <div className="container-fluid">
      <MerchantIcon setMid={setMid} reset={reset} />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="col-12">
              <div className="card-body">
                <section className="lincpay-dashboard-sec">
                  {/* <div className="d-flex justify-content-end"> */}

                  {/* <div className='d-flex gap-2 justify-content-center'>
          <label className="mb-1 text-dark fs-5 fw-bold">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm fs-5"
            style={{ maxWidth: "100px" }}
          >
            {entriesPerPageOptions?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          </div> */}
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        class="btn-close m-3"
                        onClick={toggleRightBar}
                      ></button>

                      <div className="fs-2 text-dark fw-bold">{type}</div>
                    </div>

                    <div
                      className="col-7 mb-4 mb-sm-0"
                      style={{ textAlign: "right" }}
                    >
                      <IconWrapper title={"Zoomin"}>
                        <span class="" onClick={incrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-plus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"Zoomout"}>
                        <span class="" onClick={decrementZoom}>
                          <i class="fa-solid fa-magnifying-glass-minus"></i>{" "}
                        </span>
                      </IconWrapper>
                      <IconWrapper title={"reset"}>
                        <i className="fa fa-refresh" onClick={HandleReset}></i>
                      </IconWrapper>
                      <IconWrapper title={"Multiple Filter"}>
                        <i
                          class="fa fa-filter"
                          onClick={handleAdvanceFilter}
                        ></i>
                      </IconWrapper>
                      {/* <IconWrapper title={"Multiple Filter"}>
                        <i class="fa fa-filter" onClick={handleMultipleFilter}></i>
                      </IconWrapper> */}
                    </div>
                  </div>

                  <div className="dasboard-view-count-boxes">
                    <Card.Body>
                      <div className="report-grid-view">
                        <div
                          className="material-table-records mb-3"
                          style={{ height: "500px" }}
                        >
                          {showLoader ? (
                            <Shimmer type="table" />
                          ) : isAdvanceFilterOpen &&
                            isAdvance &&
                            data2.length === 0 ? (
                            <div className="no-data-found">
                              No data found for the selected filters.
                            </div>
                          ) : !isAdvanceFilterOpen &&
                            data.length === 0 &&
                            data2.length === 0 ? (
                            // <div className="no-data-found">No data found.</div>
                            <DataNotFound />
                          ) : !data2?.length ? (
                            <>
                              <DataGrid
                                rows={data}
                                // columns={columns}
                                columns={columns.map((column) => ({
                                  ...column,
                                  renderCell: (params) =>
                                    column.field === " callbackJSON"
                                      ? params.value
                                      : params.value,
                                }))}
                                slots={{
                                  toolbar: CustomToolbar,
                                }}
                                hideFooterPagination={true}
                                // className="mb-1 text-dark fw-bold"
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  }, // Adjust the font size as needed
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  margin: "0.8rem",
                                }}
                              >
                                <Pagination
                                  count={txnSize}
                                  page={page}
                                  onChange={handleChange}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <DataGrid
                                rows={data2}
                                // columns={columns}
                                columns={columns.map((column) => ({
                                  ...column,
                                  renderCell: (params) =>
                                    column.field === " callbackJSON"
                                      ? params.value
                                      : params.value,
                                }))}
                                slots={{
                                  toolbar: CustomToolbar,
                                }}
                                hideFooterPagination={true}
                                sx={{
                                  fontSize: `${zoomCount}px`,
                                  "& .css-1rtad1": {
                                    display: "block",
                                  },
                                }}
                              />
                              <Pagination
                                count={txnSizeM}
                                page={pageM}
                                onChange={handleChangeMultipleFilter}
                              />
                            </>
                          )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <DownloadCsv
                            DownLoadData={!data2.length ? data : data2}
                            // Header={headerLabelsForPayout}
                            Name={"Payout"}
                          /> */}
                          {!data2.length ? (
                            <div className="pagination-buttons d-flex justify-content-end gap-2">
                              {/* <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                                disabled={changeCount === 0}
                                onClick={() => setChangeCount(changeCount - 1)}

                              >
                                Previous
                              </button>
                              <button className="badge badge-rounded badge-primary mx-2 px-4 py-2"
                                onClick={() => setChangeCount(changeCount + 1)}
                              >Next
                              </button> */}
                            </div>
                          ) : null}
                          {/* {totalAmountForSuccess != "0" ? (
                            <div className="d-flex align-items-center justify-content-end w-25 gap-4 border boeder-1 p-2 ">
                              <lable className="fs-4 w-50">Total Amount</lable>
                              <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
                                {totalAmountForSuccess}
                              </span>
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                    </Card.Body>

                    {/* <MidFiter
            reportType={reportType}
            setShow2={setShow2}
            show2={show2}
            setLiveData={setData}
          /> */}
                    {showResponse && (
                      <ViewResponse
                        setShowResponse={setShowResponse}
                        alertData={alertData}
                        m={m}
                        company={company}
                      />
                    )}
                    {isAdvanceFilterOpen ? (
                      <Filters
                        setTransactionReportData={setData2}
                        setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        dropDownData={dropDownData}
                        type={type}
                        mid={mid}
                        setSizeM={setSizeM}
                        sizeM={sizeM}
                        pageM={pageM}
                        setShowLoader={setShowLoader}
                        setSuccessAmount={setSuccessAmount}
                        setTxnSizeM={setTxnSizeM}
                        setIsAdvance={setIsAdvance}
                        ViewDetailsCallbackResponse={
                          ViewDetailsCallbackResponse
                        }
                      />
                    ) : null}
                    {/* {isAdvanceFilterOpen ? (
                      <LivePayoutAdvanceFilter
                        setTransactionReportData={setData}
                        setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                      />
                    ) : isMultipleFilterOpen ? (
                      <LivePayoutMultipleFilter
                        setTransactionReportDataM={setData2}
                        setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                        dropDownData={dropDownData}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                  {/* </Container>  */}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingReports
        type="reports"
        setCondition={setCondition}
        mid={mid}
        // reportType={type}
        setData={setData}
        reset={reset}
      />

      {show === true ? (
        <NewDowloadCSV type="payout" show={show} setShow={setShow} />
      ) : null}
      {/* <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download Payout Report</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" className="fs-5 btn btn-md" onClick={handleClose}>
          Close
        </Button>
        <DownloadCsv
                    DownLoadData={data}
                    Name={`Live Payout Report`}
                  />  
      </Modal.Footer>
    </Modal> */}
    </div>
  );
};

export default Payout;
