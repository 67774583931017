import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  GET_DOCUMENT_FILE,
  GET_DOCUMENT_UPLOADED_BY_ID,
} from "../../utils/constant";
import { Card } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import Loader from "../Loader/Loader";

const DocumentList = ({ id }) => {
  const [documentsNames, setDocumentsNames] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const { apiCallFnforGet } = useApiServices();

  const token = localStorage.getItem("token");

  const handleViewsingleDocuments = async (name) => {
    console.log(name);
    const headers = {
      Authentication: `Bearer ${token}`,
    };
    setShowLoader(true);
    await axios
      .get(GET_DOCUMENT_FILE + name, {
        responseType: "arraybuffer",
        headers,
      })
      .then((response) => {
        // Determine the file extension and MIME type
        const fileExtension = name.split(".").pop().toLowerCase();
        let mimeType;

        switch (fileExtension) {
          case "pdf":
            mimeType = "application/pdf";
            break;
          case "jpg":
          case "jpeg":
            mimeType = "image/jpeg";
            break;
          case "png":
            mimeType = "image/png";
            break;
          case "gif":
            mimeType = "image/gif";
            break;
          // Add more cases as needed for different file types
          default:
            console.error("Unsupported file type:", fileExtension);
            // mimeType = 'application/pdf';
            // break;

            return;
        }

        // Create a blob with the correct MIME type
        const blob = new Blob([response.data], { type: mimeType });
        const fileUrl = URL.createObjectURL(blob);

        // Open the file in a new tab
        setShowLoader(false);
        window.open(fileUrl, "_blank");
      })
      .catch((error) => {
        //   setShowLoader(false);
        setShowLoader(false);
        console.error("Error fetching file:", error.message);
      });
  };

  const fileNameCheck = (name, response) => {
    if (name.split(".")[1] === "jpg") {
      const pdfBlob = new Blob([response.data], { type: "image/jpg" });
      return pdfBlob;
    } else if (name.split(".")[1] === "pdf") {
      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      return pdfBlob;
    }
  };

  const handleViewDocuments = async () => {
    try {
      const response = await axios.get(GET_DOCUMENT_UPLOADED_BY_ID + id, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });
      if (response?.data?.statusCode === 200) {
        setDocumentsNames(response?.data?.data);
      } else {
        setDocumentsNames([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleViewDocuments();
  }, []);

  const findDocumentType = (input) => {
    const lowerInput = input.toLowerCase();
    switch (true) {
      case lowerInput.includes("shoplogo"):
        return "SHOP LOGO";
      case lowerInput.includes("shopimage"):
        return "SHOP IMAGE";
      case lowerInput.includes("shop"):
        return "SHOP";
      case lowerInput.includes("ownerpan"):
        return "OWNER PANCARD";
      case lowerInput.includes("companypancard"):
        return "COMPANY PANCARD";
      case lowerInput.includes("orgpan"):
        return "ORGANISATION PANCARD";
      case lowerInput.includes("aadhar"):
        return "AADHAR CARD";
      case lowerInput.includes("cancelcheque"):
        return "CANCEL CHEQUE";
      default:
        return input;
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <>
        <div>
          <h3>Document List</h3>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {documentsNames?.map((fileObject, index) => (
                <tr key={index}>
                  {/* <td>{fileObject["Orignal name"]}</td> */}
                  <td>{findDocumentType(fileObject["convertedName"])}</td>
                  <td>
                    <button
                      className="badge badge-rounded badge-warning"
                      onClick={() =>
                        handleViewsingleDocuments(fileObject["convertedName"])
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};

export default DocumentList;
