import { config, getCompleteDomain, getServerEnvironment } from "./helper";

const { Url } = config(getServerEnvironment(getCompleteDomain()));

export const SWIPELINC_API = Url;

export const LOGIN_API = `${SWIPELINC_API}MerchantAdminPanel/admin/login`;

export const FORGOT_PASSWORD_API = `${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/forgotpassword`;

//  export const  GET_ALL_MERCHANT_PHASE_2_APPROVED =`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`;

export const OTP_VERIFY_API = `${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/otpverify/`;

export const CHANGE_PASSWORD = `${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/changepassword`;

// REPORTS API:

export const CHARGE_BACK_UPLOAD_API = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/upload`;

export const GET_BALANCE_BY_ID = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getBalanceById`;

export const GETALL_CHARGE_BACK_API = `${SWIPELINC_API}MerchantAdminPanel/chargeBack`;

export const GET_CHARGE_BACK_BY_CONDITION_API = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByCondition`;

export const GETALL_SETTLEMENT_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/admin/settlement`;

export const SETTLEMENT_UPLOAD_API = `${SWIPELINC_API}MerchantAdminPanel/settlement/upload`;

export const GET_SETTLEMENT_BY_CONDITION_API = `${SWIPELINC_API}MerchantAdminPanel/settlement/ByCondition`;

export const PAYIN_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReport`;

export const PAYIN_REPORT_BY_CONDITION_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByCondition`;

export const PAYOUT_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/admin/payout/getAllUsage`;

export const WITHDRAW_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllWithdrawal`;

// PHASE1 APIS:

export const GETALL_SUBMITTED_MERCHANT_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Submitted`;

export const GET_VIEW_APPROVE_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Approved`;

export const GET_VIEW_REJECTED_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/rejected`;

export const GET_VIEW_PENDING_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Pending`;

//  VIEW FILE APIS:
export const VIEW_FILE_UPDATE_STATUS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`;

export const VIEW_FILE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`;

export const GET_MERCHANT_BY_ID_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`;

export const FETCH_PAYMENT_SERVICES_PHASE1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`;

export const SAVE_SELECTED_PAYMENT_SERVICES_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/`;

//   PHASE 2 APIS:

export const STAGE2_APPLICATIONS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Submitted`;

export const CHECK_FILE_IS_PDF = `${SWIPELINC_API}MerchantPanel/merchant/onboard/checkPdf`;

export const STAGE2_APPROVED_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Approved`;

export const STAGE2_REJECTED_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/rejected`;

export const STAGE2_PENDING_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Pending`;

// MOA DETAILS API:
export const STAGE2_UPDATE_STATUS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStage2/`;

export const STAGE2_GET_MERCHANT_BY_ID_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById`;

export const STAGE2_HANDLE_VIEW_DATA_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/viewData/`;

export const STAGE2_VIEW_PDF_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/`;

// VIEW MERCHANT APIS:
export const FETCH_MERCHANT_DETAILS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_MERCHANT_OPEN_DETAILS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getmerchantByMid`;

export const FETCH_MERCHANT_TRANSACTION_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantWithTransaction`;

export const FETCH_VIEW_MERCHANT_TRANSACTION_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreport`;

// TOOLS APIS:
// ALL PAYOUT REQUEST APIS:

export const FETCH_ALL_PAYOUT_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout/`;

export const FETCH_APPROVED_PAYOUT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Approved`;

export const FETCH_REJECTED_PAYOUT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Rejected`;

export const FETCH_SUBMITTED_PAYOUT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Submitted`;

export const FETCH_VIEW_DETAILS_PAYOUT_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout`;

export const FETCH_VIEW_DETAILS_UPDATE_PAYOUT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/reject`;

export const FETCH_VIEW_DETAILS1_PAYOUT_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout/`;

// ALL VENDOR REGISTRATION APIS:

export const FETCH_ALL_VENDORS_REGISTRATION_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors`;

export const FETCH_APPROVED_VENDOR_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Approved`;

export const FETCH_REJECTED_VENDOR_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Rejected`;

export const FETCH_SUBMITTED_VENDOR_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Submitted`;

export const FETCH_VIEW_VENDOR_DETAILS_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById/`;

export const FETCH_VIEW_VENDOR_UPDATE_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/updateStatus`;

export const FETCH_VIEW_VENDOR_DETAILS1_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById`;

// MERCHANT MANAGEMENT APIS :

export const FETCH_FLAG_EDIT_FORM_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/updateMerchantsByMid`;

export const FETCH_FLAG_MANAGEMENT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchantsFlags`;

export const FETCH_MANAGE_AMOUNT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/hold/getholddata/`;

export const FETCH_SERVICE_CHARGE_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_SAVE_SERVICE_CHARGE_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/save/serviceCharge`;

export const FETCH_WITHDRAW_AMOUNT_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_WITHDRAW_AMOUNT_SAVE_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/save`;

export const FETCH_WITHDRAWL_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllPandingWithdrawal`;

export const FIND_BY_MID_AND_ORDERNO = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByMidAndOrderNo`;

export const UPLOAD_MERCHANT_DOCS = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByMidAndOrderNo`;

export const FETCH_ALL_ACCOUNT_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAll/AccountDetails`;

export const FETCH_ACCOUNT_DETAILS_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAll/AccountDetails/ByMid/`;

export const FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT = `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/viewData/`;

export const FETCH_ALL_FRM_SUBMITTED_FORM = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm/getAllFRM`;

export const FETCH_FRM_SUBMITTED_FORM_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm/getFRMByMid/`;

export const FETCH_ALL_MERCHANT_VALIDATIONS = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`;

export const FETCH_ALL_BANK_FORM_SUBMITTED = `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getall`;

export const FETCH_BANK_FORM_SUBMITTED_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/mdr/getAllBymid/`;

export const UPLOAD_SWITCH_FILE = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/switch/upload`;

export const UPLOAD_NODAL_FILE = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/consolidated/upload`;

export const UPLOAD_NODAL_TRUE_OR_FALSE = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/isSwitchFile/upload`;

export const GET_RECONCILIATION_DATA = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getAll/reconciliationData`;

export const GET_UPLOADED_PDF_FILES = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getUploaded/pdfs`;

export const GET_DOWNLOAD_EXCEL = `${SWIPELINC_API}MerchantAdminPanel/merchant/CSV/Download/payin`;

export const PAYIN_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalCondition`;

export const PAYIN_FILTER_API_CSV_DOWNLOAD = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalConditionForCsvDownload`;

export const GET_TXN_REPORT_ADVANCE_FILTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByCondition`;

export const SETTLEMENT_MULTIPLE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/admin/settlement/getSettlementByMultipalCondition`;

export const CHARGEBACK_MULTIPLE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByMultpalCondition`;

export const PAYOUT_MULTIPLE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/merchantPayoutRegister/byMultipleCondition`;

export const WITHDRAW_MULTIPLE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/ByMultpalCondition`;

export const LIVE_WITHDRAWL_MULTIPLE_FILTER_API = `${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/ByMultpalConditionWithoutMid`;

export const GURANTEE_MULTIPLE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/admin/guarantAmount/chargeBack/ByMultpalCondition`;

export const GET_ALL_MECHANT_PAYOUT_MASTERS = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMaster`;

export const GET_MECHANT_PAYOUT_MASTERS_BY_VEHICLE = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/findPayoutMasterDataByVehicleMaster/`;

export const SAVE_MERCHANT_PAYOUT_MASTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/saveMerchantPayoutMaster`;

export const DISABLED_DATA_PAYOUT_MASTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMaster/DisableData`;

export const GET_ALL_ROUTING_VIEW = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/view-All`;

export const GET_WALLET_BALANCE_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/get/walletBalance/`;

export const FIND_BY_ORDER_NO = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByOrderNo`;
export const FIND_PAYIN_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getPayinDetail/id`;

export const SID_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllEnableDisableSid`;

export const GET_ALL_SUBMITTED_PAYOUT_ROUTING = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/getAllMerchantPayoutMaster`;

export const GET_ALL_DAILY_TRANSACTION_DATA_BOX = `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getDataBy/tmeframe`;

export const GET_ALL_DAILY_TRANSACTION_DATA_TABLE = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReportWithCurrentDate`;

export const GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getDataBy`;

export const GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReportWithCurrentDateWithMid`;

export const GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION = `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/getReconciliationByMultipleConditions`;

// UPDATE MERCHANT BANK DETAILS
export const UPDATE_MERCHANT_BANK_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/savebankDetails`;

// verify account number and ifsc code
export const ACC_NUM_IFSC_VERIFY_API = `${SWIPELINC_API}MerchantPanel/merchant/ekyc/bankVerification`;

/* get details by ifsc */
export const IFSC_VERIFY_API = `${SWIPELINC_API}MerchantPanel/merchant/ekyc/findByIfsc`;

// verify email is pre verified or not
export const VERIFY_MERCHANT_ONBOARD_EMAIL = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/verified/email
`;

// verify mobile is pre verified or not
export const VERIFY_MERCHANT_ONBOARD_MOBILE = `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;

export const SAVE_ORGANISATIONAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/organisational-types/save`;

export const GET_ALL_ORGANISATIONAL_TYPE = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/getAll`;

export const GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/getAllWithActive`;

export const GET_ALL_ACTIVE_VEHICAL_MASTER = `${SWIPELINC_API}/MerchantAdminPanel/admin/vehicleMaster/getAllSecondPhase`;

export const GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/api/vehicle-commercial-types/getAllWithActive`;

export const GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/getAllVehicleSettlementType/isActiveTrue`;

export const GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/getAllVehicleCommercial/isActiveTrue`;

export const GET_ALL_VEHICLE_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/merchant/paymentType/getALLPaymentTypeWithSubType`;

export const GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/vehicleSubType/getAllActive`;

export const SUBMITTED_VEHICLE_SUBTYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/getAll`;

export const SUBMITTED_VEHICLE_TYPE = `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getAllAtiveAndDisable`;

export const SUBMITTED_VEHICLE_COMMERCIAL = `${SWIPELINC_API}MerchantAdminPanel/admin/getAllVehicleCommercial`;

export const SAVE_VEHICAL_COMMERCIAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/save`;

export const SAVE_VEHICAL_SETTLEMENT_TYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/saveVehicleSettlementType`;

export const GET_ALL_VEHICAL_COMMERCIAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/getAll`;

export const GET_ALL_VEHICAL_SETTLEMENT_TYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/getAllVehicleSettlementType`;

export const GET_ALL_VEHICAL_MASTER_FORMS = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAllActive`;

export const GET_ALL_VEHICAL_MASTER_FORMS_EKYC = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/findVehicleMasterByServiceType`;

export const GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAll`;

export const SAVE_VEHICLE_MASTER_FORM = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/save`;

export const SAVE_VEHICLE_MASTER_UPDATE_FORM = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/update`;

export const SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/update`;

// export const  GET_ALL_MERCHANT_PAYOUT_API_TEST =`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`;

export const GET_LIVE_TXN_REPORT_ADVANCE_FILTER = `${SWIPELINC_API}/MerchantAdminPanel/merchant/reports/getreportByConditionWithoutMid`;

export const LIVE_PAYIN_MORE_FILTER_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalConditionWithoutMID`;

export const LIVE_PAYIN_MORE_FILTER_API_CSV_DOWNLOAD = `${SWIPELINC_API}/MerchantAdminPanel/merchant/reports/getreportByMultpalConditionWithoutMIDForCsvDownlload`;

export const GET_ALL_TEMPORARY_REQUEST_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/period/getAllWithdrawalRequest`;

export const GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllLiveTransactionsForOneDay`;

export const GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK = `${SWIPELINC_API}MerchantAdminPanel/getAllLiveTransactionsForOneDayChargeBackData`;

export const GET_ALL_BY_ID_USING_GET = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getbyId/`;

export const GET_ALL_PHASE2_SUBMITTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/getAllVehicleMasterSubPaymentTypeService`;

export const GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllLiveTransactionsForOneDayWithdrawalData`;

export const GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getAllLiveTransactionsForOneDayReconciliationData`;

export const GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/payoutRagister/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_CHARGEBACK_ = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByMultpalConditionWithoutMid`;

export const CHANGE_TXN_STATUS_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/update-txn-status`;

export const GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/getAllLiveTransactionsForOneDay/`;

export const CALLBACK_PAYOUT_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/findByMid/`;

export const CHECK_CALLBACK_FOR_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/CheckPayoutCallBack`;

export const CALLBACK_PAYIN_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getByMid/`;

export const CHECK_CALLBACK_PAYIN = `${SWIPELINC_API}MerchantAdminPanel/merchant/payin/checkPayinCallbackUrl`;

export const GET_ALL_MERCHANT_MASTER = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants`;

export const LIVE_TRANSACTION_PAYOUT_WITHOUT_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/payoutRagister/merchantPayoutRegister/byMultipleConditionWithoutMid`;

export const GET_ALL_SCHEME = `${SWIPELINC_API}MerchantAdminPanel/api/schemes`;

export const GET_ALL_BANK = `${SWIPELINC_API}MerchantAdminPanel/api/banks`;

export const GET_ALL_SWITCH_DATA = `${SWIPELINC_API}MerchantAdminPanel/api/switches`;

export const GET_ALL_AGGREGATOR_DATA = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators`;

export const GET_TXN_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantTransactionCalculation`;

export const DISABLE_VEHICLE_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/disable`;

export const ENABLE_VEHICLE_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/enable`;

export const VEHICLE_MASTER_PHASE_2_SAVE = `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/save/VehicleMasterSubPaymentTypeService`;

export const VEHICLE_MASTER_PHASE_2_SUBMITTED = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAllSecondPhase`;

// export const   VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID= `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getbyId/`;

export const VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID =
  "https://api2.lincpay.in/MerchantAdminPanel/admin/vehicleMaster/getbyId/";

export const SUBMITTED_PAYOUT = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Submitted`;

export const FETCH_PAYOUT_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout/`;

export const APPROVE_REJECT_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/reject`;

export const SINGLE_PAYIN_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreport`;

export const GET_PAYIN_BY_MULTIPLE_FILTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getReportBuMultipalConditionWithCountTxnStatusCode`;

export const SAVE_WITHDRAWL_PERCENTAGE = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/period/update`;

export const FETCH_ALL_MERCHANT_PAYIN_ROUTING_ONBORDING = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getMerchantPayin`;

export const FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getMerchantPayout`;

export const FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING = `${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getallmerchant-Ekyc`;

export const FETCH_ALL_SUBMITTED_EKYC_ROUTING = `${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getAllRouting`;

export const FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS = `${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getallmerchant-Ekyc/tools`;

export const AGGREGATOR_SAVE = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/save`;

export const GET_ALL_AGGREGATOR = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/`;

export const GET_ALL_PRIVILAGES = `${SWIPELINC_API}MerchantAdminPanel/admin/privileges/getAllPrivileges/subType`;

export const GET_ALL_PRIVILAGES_BY_ID = `${SWIPELINC_API}MerchantAdminPanel/admin/privileges/getByMasterMerchntId/`;

export const FETCH_PAYIN_FOR_ACKNOWLEDGMENT = `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllCallBackAcknowledgement`;

export const AGGREGATOR_UPDATE = `${SWIPELINC_API}/MerchantAdminPanel/api/master-merchants/update`;

export const EnableDisableAggragator = `${SWIPELINC_API}/MerchantAdminPanel/api/master-merchants/`;

export const GET_ALL_USER = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/getByMasterMerchntId/`;

export const GET_ALL_SUB_USER = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/getAll`;

export const AGGREGATOR_USERS_SAVE = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/save`;

export const GET_USER_MANAGEMENT_DATA_BY_ID = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/`;

export const USER_UPDATE = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/update`;

export const FRM_BLOCK_USER = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/save`;

export const FETCH_FRM_USERS = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/get`;

export const FETCH_MERCHANT_FOR_MASTERS_FORMS = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/findVehicleMasterByServiceType`;

export const FETCH_MERCHANT_FOR_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getMasterMaschantDetailDemo`;

export const VEHICLE_MASTER_PHASE_2_UPDATE = `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/update/updateVehicleSubPaymentTypeService`;

export const GET_ALL_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;

export const GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER = `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getAllMerchantByCondition`;

export const POST_SID_MASTER_V = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/save`;

export const FETCH_ALL_SID_MASTER_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;

export const SID_DISABLE_API = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/disableSid/`;

export const GET_All_MAPPED_SID = ` ${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/`;

export const UPDATE_SID_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/UpdateSid`;

export const CHECK_DUPLICATE_ENTRIES = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getMerchantPayoutMasterWithOneTransferType`;

export const SUBMITTED_PHASE1_APPLICATIONS = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findApplicationStatusAndStages`;

export const HANDLE_PHASE1_APPROVE = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`;

export const HANDLE_PHASE1_REJECT = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`;

export const GET_MERCHANT_BY_ID = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`;

export const GET_ALL_PAYMENT_TYPE = `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`;

export const SAVE_UPDATED_PAYMENT_SERVICES = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/`;

export const UPDATE_PAYOUT_SUBMIT = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/update/`;

export const ENABLE_DISABLE_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/`;

// dashboard api

export const TOTAL_TRANSACTION = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/totaltransection`;

export const TOTAL_TRANSACTION_AMOUNT = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/totaltransectionamount`;

export const TREND_CHART_API = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/getdashboardTrendchart/`;

export const API_DASHBOARD_WITH_CONDITION = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/combined-api`;

// tools section:

export const GET_ALL_MERCHANT_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_FULL_MERCHANT_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchantData`;

export const FETCH_ONBOARD_MERCHANT_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getByMid/`;

export const UPDATE_ONBOARD_MERCHANT_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateOnboard`;

export const UPDATE_BANK_DETAILS_STAGE2 = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateOnboard/BankDetailStage2`;

export const GET_ALL_MDR_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getAllMdrByMid/`;

export const UPDATE_MDR_PERCENTAGE = `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/updateMdrPercentage`;

export const GET_DEFAULT_SERVICES_MDR = `${SWIPELINC_API}MerchantAdminPanel/merchant/PayoutTransactionFees/findByMid/`;

export const SAVE_SERVICE_CHARGE = `${SWIPELINC_API}MerchantAdminPanel/merchant/save/serviceCharge`;

export const FETCH_BUSINESS_CATEGORIES = `${SWIPELINC_API}MerchantPanel/merchant/business/getAllBusinessType`;

export const FETCH_REGISTRATION_TYPES = `${SWIPELINC_API}MerchantPanel/merchant/registration/getAllRegistrationType`;

export const FETCH_BUSINESS_TYPES = `${SWIPELINC_API}MerchantPanel/merchant/company/getAllCompanyType`;

export const ONBOARD_SAVE_PHASE1 = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/save`;

export const FETCH_PAYMENT_TYPES = `${SWIPELINC_API}MerchantPanel/merchant/paymentType/getALLPaymentType`;

export const FETCH_CITY_STATE_BY_PINCODE = `${SWIPELINC_API}MerchantPanel/merchant/address/getCityStateByPinCode/`;

export const SAVE_MERCHANT_DOCUMENT = `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/savemerchantdocuments`;

export const fIRST_STAGE_APPROVE_SECOND_NOT_SUBMITTED = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/FirstStageApproveAndSecondNotSubmitted`;

export const SUBMITTED_VENDOR = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Submitted`;

export const VIEW_VENDOR_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById/`;

export const APPROVE_VENDOR = `${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/updateStatus`;

export const UPDATE_WALLET_BALANCE = `${SWIPELINC_API}MerchantAdminPanel/merchant/wallet/update`;

export const GET_WALLET_BALANCE = `${SWIPELINC_API}MerchantAdminPanel/merchant/wallet/status/pending`;

export const CHARGEBACK_UPLOAD = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/upload`;

export const SAVE_WITHDRAW = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/save`;

export const UPDATE_WITHDRAWL = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/updateWithdrawal`;

export const GET_ALL_PENDING_WITHDRAWL = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllPandingWithdrawal`;

export const SAVE_DROP_CREDIT = `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/save`;

export const SAVE_DATE_AND_FILE_TYPES = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/save/date`;

export const FETCH_UPLOAD_HISTORY = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getDate`;

export const FRM_BLOCK_UPLOAD = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/upload`;

export const FETCH_ALL_PAYIN_SERVICES = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/getpaymentserviceswithpercentage`;

export const REPORT_ERROR_CODES = `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getDataBy/tmeframe`;

export const GET_ALL_INITIAL_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;

export const HANDLE_SEARCH_LIMIT = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/`;

export const CHECK_MAXIMUM_AMOUNT_LIMIT = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/save`;

export const GET_DETAILS = ` ${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/get-mapped-sid/byMid/`;

export const ENABLE_DISABLE_PAYOUT_ROUTING = ` ${SWIPELINC_API}MerchantAdminPanel/payoutRouting/`;

export const UPDATE_MERCHANT = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/updateMerchantPayoutMaster`;

export const UPDATE_ALL_DATA_ADMIN_ = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/updateAllData`;

export const SAVE_FRM_DATA = `${SWIPELINC_API}/MerchantAdminPanel/merchant/frm/save`;

export const GET_ALL_FRM = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforFRM`;

export const SIDMASTER_GET_ALL_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`;

export const EKYC_SAVE = ` ${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/save`;

// export const GET_DETAILS = ${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/get-mapped-sid/byMid/

export const GET_ALL_FOR_ACCOUNT = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforAccount`;

export const ACCOUNT_DETAILS_UPLOAD = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/Account/details/upload`;

export const DEFAULT_SERVICES_MDR = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/PayoutTransactionFees/save`;

export const MDR_TYPE_FETCH = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAllServices/`;

export const WITHDRAW_TYPE = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal-type/getAllwithdrawal-type`;

export const View_PDF = ` ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/`;
export const GET_HOLD_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/hold/getholddata/`;

export const CHANGE_AMOUNT_HOLD_DEPOSITE = `${SWIPELINC_API}MerchantAdminPanel/merchant/`;

export const GET_ALL_DATA_ADMIN_HOLD = `${SWIPELINC_API}MerchantAdminPanel/merchant/holdAmountpercentage/getMerchantHoldPercentageByMid/`;
export const GET_SECURITY_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/securitydeposit/getsecuirtydata/`;
export const ALL_GET_SECURITY_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/securitydeposit/save`;

export const GET_ALL_MERCHANT = `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;
export const GET_ALL_DEPOSIT_AND_HOLD = `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getdepositeAndHoldAmount`;

export const RELEASE_AMOUNT = `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getdepositeAndHoldAmount`;

// export const UPDATE_PAYOUT_SUBMIT = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/update/`;

// export const ENABLE_DISABLE_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/`;

export const GET_VIRTUAL_ACCOUNT_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMasterDistinctData/`;

export const POST_MERCHANT_SAVE = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/saveMerchantPayoutRouting`;

export const GET_PREV_MAPPED_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/get-mapped-sid/byMid/`;

export const MASTER_MERCHANT_ENABLE_DISABLE = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/`;
export const GET_ALL_MERCHANT_TRANSACTION = `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantWithTransaction`;

export const PAYOUTROUTING_FIND_MERCHANT = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/findMerchantByMid/`;

export const POST_WALLET_BALANCE = `${SWIPELINC_API}MerchantPanel/merchant/thirdPartyApiPayout/get/walletbalance`;

export const POST_PAYOUT_API_TEST = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/payout-api-test`;

export const TOOLS_PAYIN_ENABLE_DISABLE = `${SWIPELINC_API}/MerchantAdminPanel/admin/MerchantRouting/`;

export const GET_REPORT_API = `${SWIPELINC_API}/MerchantAdminPanel/MerchantAdminPanel/LiveReport/allReports/tmeframe`;

export const ENABLE_DISABLE_TOOLS_PAYIN = `${SWIPELINC_API}/MerchantAdminPanel/admin/MerchantRouting/`;

export const PHASE2_SUBMIITED = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Submitted`;

export const UPDATE_STAGE2 = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStage2`;

export const HANDLE_VIEW_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/viewData/`;

export const GET_DASHBOARD_CURRENT_DATA = `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard/getamountdata/`;

export const SAVE_DEPOSIT = `${SWIPELINC_API}MerchantAdminPanel/merchant/securitydeposit/save`;

export const ENABLE_DISABLE_USER_MANAGEMENT = `${SWIPELINC_API}/MerchantAdminPanel/api/aggregators/subUser/`;

export const DOWNLOAD_REPORTS_CSV_BY_TYPE = `${SWIPELINC_API}MerchantAdminPanel/merchant/CSV/csv`;

// export const GET_MERCHANT_BY_ID = `${SWIPELINC_API} `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`;

// export const GET_RECONCILIATION_PAYIN_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport`;

// export const errorCodes = {
//   "U91": "#6bbdff",
//   "U90": "#fceab3",
//   "U89": "#fffa80",
//   "U86": "#ff44a6",
//   "U78": "#3bc7eb",
//   "U68": "#ffe637",
//   "U67": "#00a3cc",
//   "U66": "#f68fb0",
//   "U54": "#e8e1d9",
//   "U31": "#a5afa5",
//   "U30": "#8e9bb0",
//   "U28": "#ff8f4d",
//   "U16": "#f4c4a8",
//   "U09": "#9abdbb",
//   "U03": "#49bde4",
//   "S96": "#83cdd2",
//   "S95": "#f6f3b2",
//   "HS3": "#ff8f4d",
//   "E05": "#dca9a1",
//   "U17":"#E6E6FA",
//   "U19":"#F8F4E1",
//   "U29":"#E0FBE2",
//   "U69":"#C6EBC5",
//   "000": "#6bbdff",
//   "Success": "#7effd3"
// };

export const GET_BY_SETTLEMENT_MID = `${SWIPELINC_API}MerchantAdminPanel/admin/settlement/findbymid/`;

export const CHARGEBACK_UPDATE = `${SWIPELINC_API}/MerchantAdminPanel/chargeBack/findBymid/`;

export const HOLD_AMOUNT_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/hold/getHoldReport/`;

export const GET_DEPOSIT_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantDepositReport/getDepositeReport/`;

export const GET_PAYOUT_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByMid/`;

export const GET_WITHDRAWAL_REPORT = `${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/getAllSuccessAndReject/`;

export const GURANTEE_REPORT = `${SWIPELINC_API}/MerchantAdminPanel/admin/guarantAmount/findAllByMid/`;

export const AVAILABLE_CREDIT_AMOUNT = `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/`;

export const SETTLEMENT_ADJUSTMENT_REPORT = `${SWIPELINC_API}/MerchantAdminPanel/admin/get-by-mid-and-remark/`;

export const VENDOR_REPORT = `${SWIPELINC_API}/MerchantAdminPanel/merchant/vendor/getAllVendorsbyMid/`;

export const RELEASE_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getBy/`;

export const WALLETBALANCE_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/wallet/findByMid/`;

export const CHARGEBACK_UPDATE_REPORT = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/update`;

export const WITHDRAWL_DELETE = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/deleteBy/`;

export const GET_ALL_MCC_CODE = `${SWIPELINC_API}MerchantAdminPanel/ucoBank/getAllMccCode`;

export const GET_CHANNEL_AND_TXN_SET = `${SWIPELINC_API}/MerchantAdminPanel/ucoBank/getChannelAndTransacetionSet`;

export const GET_DEVICE_MODAL = `${SWIPELINC_API}/MerchantAdminPanel/ucoBank/getDeviceModel`;

export const SAVE_BANK_ONBOARD = `${SWIPELINC_API}/MerchantAdminPanel/yesBank/onBoard/bank`;

export const ENABLE_SIDMASTER = `${SWIPELINC_API}/MerchantAdminPanel/admin/sidMaster/enableSid/`;

export const GET_ALL_DISABLED_SID_FOR_MASTERS = `${SWIPELINC_API}/MerchantAdminPanel/admin/sidMaster/getAllDisableSid`;

export const API_FOR_VIEW_LOGS = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getSidLogs/`;

export const GET_ALL_CUB_ONBOARDING = `${SWIPELINC_API}MerchantAdminPanel/ucoBank/getAllOnBoarding`;

export const GET_UCB_BANK_STATUS = `${SWIPELINC_API}MerchantAdminPanel/ucoBank/getstatus/`;

export const VIEW_ALL_MERCHANT_FOR_SQR = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/viewAllMerchantForSqr`;

export const GET_ALL_FREE_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllFreeSid`;

export const SAVE_QR_ROUTING = `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/saveSqrRouting`;

export const CHECK_CALLBACK_STATUS = `${SWIPELINC_API}MerchantAdminPanel/util/getStatus`;

export const GET_ALL_CREDIT = `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/getAllCreditWithAndWithoutMid`;

export const GET_ALL_DEBIT = `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/getAllDebitWithAndWithoutMid`;

export const GET_AMOUNT_RULES = `${SWIPELINC_API}MerchantAdminPanel/merchant/get/AmountRule/`;

export const GET_PAYOUT_AMOUNT_RULES = `${SWIPELINC_API}MerchantAdminPanel/merchant/get/payoutAmountRule/`;

// export const GET_PAYOUT_AMOUNT_RULES = `${SWIPELINC_API}MerchantAdminPanel/merchant/get/payoutAmountRule/`;

export const SAVE_AMOUNT_RULE = `${SWIPELINC_API}/MerchantAdminPanel/merchant/save/AmountRule`;

export const SAVE_PAYOUT_AMOUNT_RULE = `${SWIPELINC_API}MerchantAdminPanel/merchant/save/payoutAmountRule`;

export const GET_RULES_API = `${SWIPELINC_API}/MerchantAdminPanel/merchant/get/AmountRule/distinct`;

export const GET_PAYOUT_RULES_API = `${SWIPELINC_API}/MerchantAdminPanel/merchant/get/payoutAmountRule/distinct`;

export const UPDATE_MID_RULES = `${SWIPELINC_API}MerchantAdminPanel/merchant/suffle/AmountRule`;

export const UPDATE_MID_PAYOUT_RULES = `${SWIPELINC_API}MerchantAdminPanel/merchant/suffle/payoutAmountRule`;

export const DISABLE_PAYOUT_RULE = `${SWIPELINC_API}MerchantAdminPanel/merchant/disable/payoutAmountRule/`;

export const DISABLE_RULE = `${SWIPELINC_API}MerchantAdminPanel/merchant/disable/AmountRule/`;

export const FETCH_VEHICLE_BY_SERVICE_TYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/findVehicleMasterByServiceType`;

export const GET_RESPONSE_CODE = `${SWIPELINC_API}MerchantAdminPanel/admin/payin/findByTxnId/`;

export const GET_BBPS_MASTER = `${SWIPELINC_API}MerchantAdminPanel/bbps/MasterData/getMaster`;

export const POST_BBPS_MASTER_FORM = `${SWIPELINC_API}MerchantAdminPanel/bbps/MasterData/save`;

export const DISABLE_BBPS_MASTER = `${SWIPELINC_API}MerchantAdminPanel/bbps/MasterData/disable`;

export const API_FOR_ADVANCE_FILTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/CSV/Download/AdvanceFilter`;

export const API_FOR_MULTIPLE_FILTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/CSV/Download/payin`;

export const BBPS_ROUTING_SAVE = `${SWIPELINC_API}MerchantAdminPanel/bbps/routing/save`;

export const BBPS_ROUTING_GET = `${SWIPELINC_API}MerchantAdminPanel/bbps/routing/getAll`;

export const BBPS_ROUTING_DISABLE = `${SWIPELINC_API}MerchantAdminPanel/bbps/routing/disable`;

export const BBPS_ROUTING_ENABLE = `${SWIPELINC_API}/MerchantAdminPanel/bbps/routing/enable`;

export const SETTLEMENT_TYPE_GET = `${SWIPELINC_API}/MerchantAdminPanel/all`;

export const MASTER_MERCHANT_SERVICES_POST = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/service-md-setting`;

export const REPORTED_PAYIN_ISSUE_CONTROLLER_REPORT_API = `${SWIPELINC_API}MerchantAdminPanel/reportedPayinIssueController/getAllReportedPayinIssue`;

export const MASTER_MERCHANT_PHASE2 = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/service-md-settings/`;

export const EDIT_MASTER_MERCHANT_PHASE2 = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/service-md-setting/`;

export const LIVE_GUARANTEE_MULTIPLE_FILTER_API = `${SWIPELINC_API}/MerchantAdminPanel/admin/guarantAmount/chargeBack/ByMultpalCondition`;

export const MOBILE_APP_ONBOARDING_APPROVED = `${SWIPELINC_API}/MerchantAdminPanel/yesBank/getAllApproved`;

export const YES_BANK_ONBOARD_WITH_MID = `${SWIPELINC_API}/MerchantAdminPanel/yesBank/yesbankOnBoard/`;

export const GET_DOCUMENT_UPLOADED_BY_ID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/document/view-filename/`;

export const GET_VIDEO_UPLOADED_BY_ID = `${SWIPELINC_API}/MerchantAdminPanel/videoGettingController/videoName/`;

export const GET_ALL_YES_ONBOARD = `${SWIPELINC_API}/MerchantAdminPanel/yesBank/getAllYesOnBoard`;

export const GET_ALL_PAYMENT_SERVICES = `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`;

export const GET_ALL_SID_BY_VEHICLE = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSidbyVehicle/`;

export const GET_VIDEO_FILE = `${SWIPELINC_API}/MerchantAdminPanel/videoGettingController/videos/`;

export const GET_DOCUMENT_FILE = `${SWIPELINC_API}/MerchantAdminPanel/merchant/document/view-Documents/`;

export const APPROVED_MOBILE_APP_ONBOARD = `${SWIPELINC_API}MerchantAdminPanel/yesBank/update`;
