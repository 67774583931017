import React, { useState } from 'react';
import LivePayinAdvanceFilter from './LivePayinAdvanceFilter';
import LivePayinMutipleFilter from './LivePayinMutipleFilter';
import LiveChargebackAdvanceFilter from './LiveChargebackAdvanceFilter';
import LiveChargebackMultipleFilter from './LiveChargebackMultipleFilter';
import LiveWithdrawlAdvanceFilter from './LiveWithdrawlAdvanceFilter';
import LiveWithdrawlMultipleFilter from './LiveWithdrawlMultipleFilter';
import LivePayoutAdvanceFilter from './LivePayoutAdvanceFilter';
import LivePayoutMultipleFilter from './LivePayoutMultipleFilter';
import ReconAdvanceSearch from '../Tools/Merchant Management/ReconAdvanceSearch';
import ReconMultiFilter from '../Tools/Merchant Management/ReconMultiFilter';
import WalletMultipleFilter from './WallletMultipleFIlter';
import WalletAdvanceFilter from './WalletAdvanceFilter';
import ReleaseMultipleFilter from './ReleaseMultipleFilter';
import ReleaseAdvanceFilter from './ReleaseAdvanceFilter';
import SettlementMultipleFilter from './SettlementMultipleFilter';
import SettlementAdvanceFilter from './SettlementAdvanceFilter';
import LiveGuaranteeMultipleFilter from './LiveGuaranteeMultipleFilter';

const Filters = ({
  type,
  setTransactionReportData,
  setIsAdvanceFilterOpen,
  setShowMultipleFilter,
  dropDownData,
  setLiveBoxData,
  setDownloadCSVData,
  SIDData, 
  setIsMultipleFilterOpen,
  setChargebackReportData,
  setWithdrawlReportData,
  setSettlementReportData,
  setTransactionReportDataM,
  setReconReportData,
  setIsMultiFilterOpen,
  mid,
  setSizeM,
  sizeM,
  pageM,
  ViewDetailsCallbackResponse,
  setShowLoader,
  setSuccessAmount,
  setTxnSizeM,
  setSuccessData,
  setTotalTransactionCount,
  setIsAdvance,
  HandleShowResponseCode,
  setGuaranteeReportDataM,
}) => {
  const [toggleFilterA, setToggleFilterA] = useState(true);

  return (
    <div>
      {type === "Payin" ? (
        toggleFilterA ? (
          <LivePayinMutipleFilter
          setTransactionReportData={setTransactionReportData}
          setShowMultipleFilter={setShowMultipleFilter}
          dropDownData={dropDownData}
          setLiveBoxData={setLiveBoxData}
          setDownloadCSVData={setDownloadCSVData}
          SIDData={SIDData}
          setToggleFilterA={setToggleFilterA}
          toggleFilterA={toggleFilterA}
          mid={mid}
          sizeM={sizeM}
          pageM={pageM}
          ViewDetailsCallbackResponse={ViewDetailsCallbackResponse}
          setShowLoader={setShowLoader}
          setSuccessAmount={setSuccessAmount}
          setTxnSizeM={setTxnSizeM}
          setSuccessData={setSuccessData}
          setTotalTransactionCount={setTotalTransactionCount}
          setIsAdvance={setIsAdvance}
          HandleShowResponseCode={HandleShowResponseCode}
        />
        
        ) : (
          <LivePayinAdvanceFilter
          setTransactionReportData={setTransactionReportData}
          setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
          setToggleFilterA={setToggleFilterA}
          toggleFilterA={toggleFilterA}
          ViewDetailsCallbackResponse={ViewDetailsCallbackResponse}
          setShowLoader={setShowLoader}
          setSuccessData={setSuccessData}
          setIsAdvance={setIsAdvance}
          HandleShowResponseCode={HandleShowResponseCode}
        />
        )
      )
        :
        type === "Chargeback" ? (
          toggleFilterA ? (
            <LiveChargebackMultipleFilter
              setChargebackReportData={setChargebackReportData}
              setIsMultipleFilterOpen={setIsMultipleFilterOpen}
              dropDownData={dropDownData}
              setToggleFilterA={setToggleFilterA}
              toggleFilterA={toggleFilterA}
              mid={mid}
              sizeM={sizeM}
              pageM={pageM}
              setShowLoader={setShowLoader}
              setSuccessAmount={setSuccessAmount}
              setTxnSizeM={setTxnSizeM}
              setIsAdvance={setIsAdvance}
            />
          ) : (
            <LiveChargebackAdvanceFilter
            setChargebackReportData={setChargebackReportData}
              setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
              setToggleFilterA={setToggleFilterA}
              toggleFilterA={toggleFilterA}
              setShowLoader={setShowLoader}
              setIsAdvance={setIsAdvance}
            />
          )
        )
          :
          type === "Withdraw" ? (
            toggleFilterA ? (
              <LiveWithdrawlMultipleFilter
                setWithdrawlReportData={setWithdrawlReportData}
                setShowMultipleFilter={setShowMultipleFilter}
                dropDownData={dropDownData}
                setToggleFilterA={setToggleFilterA}
                toggleFilterA={toggleFilterA}
                mid={mid}
                sizeM={sizeM}
                pageM={pageM}
                setShowLoader={setShowLoader}
                setSuccessAmount={setSuccessAmount}
                setTxnSizeM={setTxnSizeM}
                setIsAdvance={setIsAdvance}
              />
             
            ) : (
              <LiveWithdrawlAdvanceFilter
              setWithdrawlReportData={setWithdrawlReportData}
                setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                setToggleFilterA={setToggleFilterA}
                toggleFilterA={toggleFilterA}
                setShowLoader={setShowLoader}
                setIsAdvance={setIsAdvance}
              />
            )
          )
            :
            type === "Payout" ? (
              toggleFilterA ? (
                <LivePayoutMultipleFilter
                  setTransactionReportData={setTransactionReportData}
                  setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                  dropDownData={dropDownData}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  mid={mid}
                  setSizeM={setSizeM}
                  sizeM={sizeM}
                  pageM={pageM}
                  setShowLoader={setShowLoader}
                  setSuccessAmount={setSuccessAmount}
                  setTxnSizeM={setTxnSizeM}
                  setIsAdvance={setIsAdvance}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  ViewDetailsCallbackResponse={ViewDetailsCallbackResponse}
                />
                
              ) : (
                <LivePayoutAdvanceFilter
                  setTransactionReportData={setTransactionReportData}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  setShowLoader={setShowLoader}
                  setIsAdvance={setIsAdvance}
                  setSuccessAmount={setSuccessAmount}
                  setTxnSizeM={setTxnSizeM}
                  ViewDetailsCallbackResponse={ViewDetailsCallbackResponse}
                />
              )
            ) : 
            type === "Settlement" ? (
              toggleFilterA ? (
                <SettlementMultipleFilter
                setReconReportData={setReconReportData}
                setIsMultiFilterOpen={setIsMultiFilterOpen}
                setToggleFilterA={setToggleFilterA}
                toggleFilterA={toggleFilterA}
                mid={mid}
                setSizeM={setSizeM}
                sizeM={sizeM}
                pageM={pageM}
                setShowLoader={setShowLoader}
                setSuccessAmount={setSuccessAmount}
                setTxnSizeM={setTxnSizeM}
                setIsAdvance={setIsAdvance}
                setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                />
                
              ) : (
                <SettlementAdvanceFilter
                setReconReportData={setReconReportData}
                setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                setToggleFilterA={setToggleFilterA}
                toggleFilterA={toggleFilterA}
                setShowLoader={setShowLoader}
                setIsAdvance={setIsAdvance}
                />
              )
            )
              :
              type === "reconciliation" ? (
                toggleFilterA ? (
                  <ReconMultiFilter
                  setReconReportData={setReconReportData}
                  setIsMultiFilterOpen={setIsMultiFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  mid={mid}
                  setSizeM={setSizeM}
                  sizeM={sizeM}
                  pageM={pageM}
                  setShowLoader={setShowLoader}
                  setSuccessAmount={setSuccessAmount}
                  setTxnSizeM={setTxnSizeM}
                  setIsAdvance={setIsAdvance}
                />
                ) : (
                  <ReconAdvanceSearch
                  setReconReportData={setReconReportData}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  setShowLoader={setShowLoader}
                  setIsAdvance={setIsAdvance}
                />
                  
                )
              ) :
              type === "Wallet" ? (
                toggleFilterA ? (
                  <WalletMultipleFilter
                  setReconReportData={setReconReportData}
                  setIsMultiFilterOpen={setIsMultiFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  mid={mid}
                  setSizeM={setSizeM}
                  sizeM={sizeM}
                  pageM={pageM}
                  setShowLoader={setShowLoader}
                  setSuccessAmount={setSuccessAmount}
                  setTxnSizeM={setTxnSizeM}
                  setIsAdvance={setIsAdvance}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                />
                ) : (
                  <WalletAdvanceFilter
                  setReconReportData={setReconReportData}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  setShowLoader={setShowLoader}
                  setIsAdvance={setIsAdvance}
                />
                  
                )
              ) 
              :
              type === "Release" ? (
                toggleFilterA ? (
                  <ReleaseMultipleFilter
                  setReconReportData={setReconReportData}
                  setIsMultiFilterOpen={setIsMultiFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  mid={mid}
                  setSizeM={setSizeM}
                  sizeM={sizeM}
                  pageM={pageM}
                  setShowLoader={setShowLoader}
                  setSuccessAmount={setSuccessAmount}
                  setTxnSizeM={setTxnSizeM}
                  setIsAdvance={setIsAdvance}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                />
                ) : (
                  <ReleaseAdvanceFilter
                  setReconReportData={setReconReportData}
                  setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                  setToggleFilterA={setToggleFilterA}
                  toggleFilterA={toggleFilterA}
                  setShowLoader={setShowLoader}
                  setIsAdvance={setIsAdvance}
                />
                  
                )
              ) : type === "Guarantee" ? (
                toggleFilterA ? (
                  <LiveGuaranteeMultipleFilter
                    setTransactionReportData={setWithdrawlReportData}
                    setIsMultipleFilterOpen={setShowMultipleFilter}
                    dropDownData={dropDownData}
                    setToggleFilterA={setToggleFilterA}
                    toggleFilterA={toggleFilterA}
                    mid={mid}
                    sizeM={sizeM}
                    pageM={pageM}
                    setShowLoader={setShowLoader}
                    setSuccessAmount={setSuccessAmount}
                    setTxnSizeM={setTxnSizeM}
                    setIsAdvance={setIsAdvance}
                    // Add other necessary props...
                  />
                ) : null)
                : null
      }
    </div>
  );
};

export default Filters;
