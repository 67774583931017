import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  DEFAULT_SERVICES_MDR,
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_ALL_MDR_BY_MID,
  GET_DEFAULT_SERVICES_MDR,
  SWIPELINC_API,
  UPDATE_MDR_PERCENTAGE,
} from "../../../utils/constant";
import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../../utils/helper";
import swal from "sweetalert";
import ServiceCharge from "../Merchant Management/ServiceCharge";
import useCheckPrivilages from "../../../utils/checkPrivilages";
// import InactiveServices from "../../InactiveServices";
import useApiServices from "../../../utils/useApiServices";

const MDRPercentage = ({ toggleRightBar, type1 }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isPayoutServiceTaken, setIsPayoutServiceTaken] = useState(false);
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");
  const [MDRTypes, setMDRType] = useState();
  const [MDRData, setMDRData] = useState([]);
  const [activePhase, setActivePhase] = useState("Phase2");
  const { apiCallFnforGet, apiCallFnforPost, apiCallFnforPut } =
    useApiServices();

  const [defaultPayoutServicesMDR, setDefaultPayoutServicesMDR] = useState({
    mid: "",
    perTxnUpi: 0,
    perTxnImps: 0,
    perTxnRtgs: 0,
    perTxnNeft: 0,
  });

  const DefaultServicesList = [
    {
      name: "perTxnUpi",
      paymentTypeId: 1,
      paymentService: "UPI",
    },
    {
      name: "perTxnImps",
      paymentTypeId: 2,
      paymentService: "IMPS",
    },
    {
      name: "perTxnNeft",
      paymentTypeId: 3,
      paymentService: "NEFT",
    },
    {
      name: "perTxnRtgs",
      paymentTypeId: 4,
      paymentService: "RTGS",
    },
  ];

  const handleDefaultServicesMDRChange = (name, fieldName, value, mdrId) => {
    console.log(name, fieldName, value, mdrId);
    const parsedValue = parseFloat(value);
    console.log(name);

    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    // if (parsedValue < 0 || parsedValue > 100) {
    //   return;
    // }

    setDefaultPayoutServicesMDR({
      ...defaultPayoutServicesMDR,
      [name]: parsedValue,
    });
  };

  useEffect(() => {
    console.log(defaultPayoutServicesMDR);
  }, [defaultPayoutServicesMDR]);

  const hasPrivilage = useCheckPrivilages(176088);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  const getMerchantData = async () => {
    try {
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);

      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
        console.log(response?.data);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      setMerchantData([]);
    }
  };

  useEffect(() => {
    getMerchantData();
  }, [mid]);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.prifix} :- ${merchant.mid} ${merchant.merchantName}` ===
        selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setDefaultPayoutServicesMDR({
        ...defaultPayoutServicesMDR,
        mid: selectedMerchant.mid,
      });
    } else {
      setMid("");

      setMDRData([]);
      setMDRType([]);
    }
  };

  const fetchtheTypeOfoption = async () => {
    setMDRData([]);
    setMDRType([]);
    // if (!mid === "") {
    try {
      const MDRType = await apiCallFnforGet(GET_ALL_MDR_BY_MID + mid);
      if (MDRType?.statusCode === 200) {
        setMDRType(MDRType?.data ? MDRType?.data : []);
        setIsPayoutServiceTaken(checkIsPayoutExistInService(MDRType?.data));
      } else if (MDRType?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "No details found",
          icon: "error",
        });
        setMDRType([]);
        setMid("");
        setSelectedCompany("");
        setFullName("");
      }
    } catch (error) {
      console.log(error);
    }

    // }
  };

  const fetchDefaultServicesMDR = async () => {
    setMDRData([]);
    setMDRType([]);
    // if (!mid === "") {
    try {
      const MDRType = await apiCallFnforPost(
        GET_DEFAULT_SERVICES_MDR + mid,
        ""
      );
      if (MDRType?.statusCode === 200) {
        // setIsPayoutServiceTaken(checkIsPayoutExistInService(MDRType?.data));
        const { perTxnImps, perTxnRtgs, perTxnNeft, perTxnUpi, mid } =
        MDRType?.data;
        setDefaultPayoutServicesMDR({
          perTxnImps,
          perTxnRtgs,
          perTxnNeft,
          perTxnUpi,
          mid,
        });
      } else if (MDRType?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "No details found",
          icon: "error",
        });
        setIsPayoutServiceTaken(false);
        setDefaultPayoutServicesMDR({
          mid: "",
          perTxnUpi: 0,
          perTxnImps: 0,
          perTxnRtgs: 0,
          perTxnNeft: 0,
        });
        setMid("");
        setSelectedCompany("");
        setFullName("");
      }
    } catch (error) {
      console.log(error);
    }

    // }
  };

  const setMDrDataInit = (MDRTypes) => {
    MDRTypes.forEach((element) => {
      setMDRData({
        ...MDRData,
        [element.paymentTypeId]: element.mdrPercentage,
      });
    });
    setMDRData();
  };

  useEffect(() => {
    fetchtheTypeOfoption();
    fetchDefaultServicesMDR();
  }, [mid]);

  const handleMDRChange = (value, mdrId, name) => {
    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    let data = MDRTypes.filter((elem) => {
      return elem.paymentTypeId === mdrId;
    });

    data[0].mdrPercentage = value;
    setMDRType([...MDRTypes]);
  };

  const handleMDRsubmit = async () => {
    const finalPayload = MDRTypes.map((type) => {
      return { ...type, mid: mid };
    });

    try {
      const response = await apiCallFnforPut(
        UPDATE_MDR_PERCENTAGE,
        finalPayload
      );

      // return response?.data;

      if (response.statusCode === 200) {
        // setMDRType([]);
        // setMid("");
        // setSelectedCompany("");

        swal({
          title: "Success",
          text: "Merchant discount rate updated successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleDefaultServicesMDRsubmit = async () => {
    try {
      const response = await apiCallFnforPost(
        DEFAULT_SERVICES_MDR,
        defaultPayoutServicesMDR
      );

      // return response?.data;

      if (response.statusCode === 200) {
        setDefaultPayoutServicesMDR({
          mid: "",
          perTxnUpi: 0,
          perTxnImps: 0,
          perTxnRtgs: 0,
          perTxnNeft: 0,
        });
        setMid("");
        setSelectedCompany("");

        swal({
          title: "Success",
          text: "Merchant fixed charges updated successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.message,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const checkIsPayoutExistInService = (MDRTypes) => {
    if (!MDRTypes) return false;
    console.log(MDRTypes)
    const ServiceIds = MDRTypes.map((service) => {
      return service.paymentTypeId;
    });
    const ServiceNames = MDRTypes.map((service) => {
      return service.paymentService;
    });
    console.log(ServiceIds, ServiceNames);
    // alert(ServiceNames.includes("PAYOUT") && ServiceIds.includes(6))
    return ServiceNames.includes("PAYOUT") && ServiceIds.includes(6);
  };

  return (
    <>
      <div className="d-flex justify-content-between bg-white custom-card-header">
        <div className="d-flex justify-content-start align-items-center mb-4">
          <button class="btn-close m-3" onClick={toggleRightBar}></button>
          <div className="fs-2 text-dark fw-bold">Merchant Discount Rate</div>
        </div>
      </div>
      <section className="lincpay-dashboard-sec chargeback-reports-sec">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <div>
              <div className="row">
                {activePhase === "Phase2" ? (
                  <div className="col-12 offset ">
                    <div className="col-sm-12 col-md-6  offset-md-3">
                      <label className="d-flex text-dark fs-4">
                        Select A Merchant
                      </label>

                      <select
                        id="companyDropdown"
                        className="form-control"
                        onChange={handleCompanyChange}
                        value={selectedCompany}
                      >
                        <option value="">Select a Merchant</option>
                        {merchantData?.map((merchant) => (
                          <option
                            key={merchant.mid}
                            value={`${merchant.prifix} :- ${merchant.mid} ${merchant.merchantName}`}
                          >
                            {merchant.prifix} :- {merchant.mid} -{" "}
                            {merchant.merchantName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="row ">
                      <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="form-group mt-2">
                          <label className="d-flex text-dark fs-4 ">
                            Merchant MID
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control "
                            id="email"
                            placeholder="Merchant ID"
                            value={mid}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ServiceCharge data={merchantData} />
                )}
              </div>
              {activePhase === "Phase2" ? (
                // <Container>
                <Row>
                  <Col xs={12}>
                    <Row className="p-3">
                      <Col xs={12} className="mb-3">
                        <label className="mx-4 text-dark fs-4 mt-2">
                          MERCHANT DISCOUNT RATE
                          <sup className="text-danger">*</sup>
                        </label>
                        <label
                          className="fw-semi-bold text-danger"
                          style={{ fontSize: "13px" }}
                        >
                          (Please enter the MDR rate in percentage.)
                        </label>
                      </Col>

                      {MDRTypes?.map((service) => (
                        <Col
                          xs={12}
                          md={4}
                          key={service.paymentTypeId}
                          className="mb-3"
                        >
                          <label className="d-flex text-dark fs-4 lh-1">
                            {service.paymentService}
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="number"
                            name={service.paymentService}
                            className="form-control"
                            value={service.mdrPercentage}
                            onChange={(e) =>
                              handleMDRChange(
                                e.target.value,
                                service.paymentTypeId,
                                service.paymentService
                              )
                            }
                            required
                          />
                        </Col>
                      ))}
                      {MDRTypes?.length > 0 && (
                        <Col xs={12}>
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="badge badge-rounded badge-primary mx-3 px-3 py-2"
                              onClick={handleMDRsubmit}
                              id="mdrSave"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      )}
                      {isPayoutServiceTaken && (
                        <Col xs={12} className="mb-3">
                          <label className="mx-4 text-dark fs-4 mt-2">
                            Payout
                            <sup className="text-danger">*</sup>
                          </label>
                          <label
                            className="fw-semi-bold text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            (Fixed charge per txn)
                          </label>
                        </Col>
                      )}
                      {DefaultServicesList?.map(
                        (service) =>
                          isPayoutServiceTaken && (
                            <Col
                              xs={12}
                              md={4}
                              key={service.paymentTypeId}
                              className="mb-3"
                            >
                              <label className="d-flex text-dark fs-4 lh-sm">
                                {service.paymentService}
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="number"
                                name={service.name}
                                className="form-control"
                                value={defaultPayoutServicesMDR[service.name]}
                                onChange={(e) =>
                                  handleDefaultServicesMDRChange(
                                    e.target.name,
                                    service.paymentTypeId,
                                    e.target.value,
                                    service.paymentService
                                  )
                                }
                                required
                              />
                            </Col>
                          )
                      )}
                      {isPayoutServiceTaken && (
                        <Col xs={12}>
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="badge badge-rounded badge-primary mx-3 px-3 py-2"
                              onClick={handleDefaultServicesMDRsubmit}
                              id="mdrSave"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              ) : (
                // </Container>
                ""
              )}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default MDRPercentage;
