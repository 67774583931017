import React, { useEffect, useState } from "react";
import { liveTxnReportAdvanceFilter as getApiData } from "../../utils/ApiServices";
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomTip from "../../common/CustomToolTip/CustomTip"
import {
  isEmailIdRegexIsValid,
  isTransactionIdRegexIsValid,
  isUtrNumRegexIsValid,
  isVpaUpiRegexIsValid,
} from "../../utils/helper";
import { Button, Modal } from "react-bootstrap";
import LivePayinMutipleFilter from "./LivePayinMutipleFilter";
import { fabClasses } from "@mui/material";

const LivePayinAdvanceFilter = ({
   setShowLoader,
   setTransactionReportData,
  setIsAdvanceFilterOpen,
  setViewDetailsCallbackResponse,
  setToggleFilter,
  setShowMultipleFilter,
  dropDownData,
  setLiveBoxData,
  setDownloadCSVData,
  SIDData,
  setToggleFilterA,
  toggleFilterA,
  ViewDetailsCallbackResponse,
  setIsAdvance,
  HandleShowResponseCode

}) => {
  const token = localStorage.getItem("token");
  // const token = tokenValue ? tokenValue.slice(1, -1) : null;
  //   const decodedToken = jwtDecode(token);
  //   const { mid } = decodedToken;
  const [searchOption, setSearchOption] = useState("transactionId");
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(true);
  const [showMutiple, setShowMultiple] = useState(false)
  

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const handleModalClose = () => {
    setShow(false);
    setIsAdvanceFilterOpen(false);
  };
  const date = new Date().toJSON().slice(0, 10);

  const handleClick = (option) => {
    setSearchOption(option);
    setSearchValue("");
  };

  const submitHandler = () => {
    // setSearch(!search)
    //   alert("hgsdjfjh")
    getApiData(
      "",
      searchOption === "transactionId" ? searchValue.trim("T-") : "",
      searchOption === "orderNumber" ? searchValue : "",
      searchOption === "email" ? searchValue : "",
      searchOption === "mobile" ? searchValue : "",
      searchOption === "payerVPA" ? searchValue : "",
      searchOption === "custRefNo" ? searchValue : "",
      searchOption === "userId" ? searchValue : "",
      "",
      token,
      setTransactionReportData,
      ViewDetailsCallbackResponse,
      setShowLoader,
      setIsAdvance,
      HandleShowResponseCode
    );

    handleModalClose();
  };

  const handelSearchData = (e) => {
    if (searchOption === "mobile") {
      const numericInput = /^\d*$/.test(e.target.value);
      if (numericInput) {
        setSearchValue(e.target.value.substring(0, 10)); // Only allow up to 10 digits
        setSearchValueError("");
      } else {
        setSearchValueError("Input must be a number.");
      }
      return;
    }

    if (searchOption === "transactionId") {
      setSearchValueError("");
      const value = e.target.value.replace("T-", "");

      setSearchValue(value.trim());
      console.log(value);
      return;
    }

    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
    document.getElementById("searchBtn").disabled = false;
  };

  const handelValidations = (e) => {
    if (searchOption === "payerVPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid VPA.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }

    if (searchOption === "email") {
      if (!isEmailIdRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid Email Id.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }

    if (searchOption === "custRefNo") {
      if (!isUtrNumRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Enter Valid UTR Number.");
        document.getElementById("searchBtn").disabled = true;
      }
      return;
    }
  };

  useEffect(() => {
    handleClick("transactionId");
  }, []);

  return (
    <div className="advanced-search-popup">
    

      <Modal show={show} centered>
        <Modal.Header className="bg-dark text-white">
          <Modal.Title>Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-field-area">
            <div className="form-group mb-3">
              <select
                className="form-control"
                value={searchOption}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                  setSearchValue("");
                  setIsSearchValueError(false);
                  setSearchValueError("");
                }}
              >
                <option value="transactionId">Transaction ID</option>
                <option value="orderNumber">Order Number</option>
                <option value="userId">User Id</option>
                {/* <option type="email" value="email">
                    e-Mail
                  </option>
                  <option value="mobile">Mobile</option>
                  <option value="payerVPA">Payer VPA</option> */}
                <option value="custRefNo">UTR No</option>
              </select>
            </div>
            <div className="form-group mb-2">
              <input
                className="advanced-input-box form-control"
                type={
                  searchOption === "transactionId"
                    ? "text"
                    : searchOption === "mobile"
                    ? "tel"
                    : "text"
                }
                value={searchValue}
                onChange={handelSearchData}
                onBlur={handelValidations}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                placeholder={
                  searchOption === "transactionId"
                    ? "Enter transaction ID"
                    : searchOption === "orderNumber"
                    ? "Enter Order Number"
                    : searchOption === "email"
                    ? "Enter e-Mail"
                    : searchOption === "payerVPA"
                    ? "Enter Payer VPA"
                    : searchOption === "custRefNo"
                    ? "Enter UTR No"
                    : searchOption === "userId"
                    ? "Enter User Id"
                    : "Enter Mobile"
                }
              />
              {isSearchValueError ? (
                <span id="searchValErr" className=" text-danger">
                  {`${searchValueError}`}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer  className="d-flex justify-content-between">
          {/* <div className="d-flex justify-content-between"> */}
          <CustomTip size="18" placement="top" title="More Filter"
          >
           <button style={{border:"none", backgroundColor:"white"}} onClick={()=>setToggleFilterA(!toggleFilterA)}>
            <FilterListIcon fontSize="large"/>
            </button>
          </CustomTip>
         <div className="d-flex gap-2">
         <Button
            variant="outline-danger"
            size="sm"
            className="text-capitlize"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            id="searchBtn"
            variant="outline-primary"
            size="sm"
            className="text-capitlize"
            onClick={submitHandler}
          >
            <i class="bi bi-search mx-1"></i> Search
          </Button>
         </div>
        </Modal.Footer>
      </Modal>
      {/* {showMutiple && 
     ( <LivePayinMutipleFilter
                    // setShowLoader={setShowLoader}
                    setTransactionReportData={setTransactionReportData}
                    setShowMultipleFilter={setShowMultipleFilter}
                    dropDownData={dropDownData}
                    setLiveBoxData={setLiveBoxData}
                    setDownloadCSVData={setDownloadCSVData}
                    SIDData={SIDData}
                  />)
      } */}
    </div>
  );
};

export default LivePayinAdvanceFilter;
